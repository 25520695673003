import * as React from 'react';
import { Typography, Container, Box, Divider, Grid, styled, CssBaseline, Stack, Card, CardMedia, CardContent } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import { logPageView } from '../../firebaseApp';
import getLPTheme from '../../getLPTheme';
import CommonAppBar from '../../components/CommonAppBar';
import Footer from '../../components/Footer';
import RequestServicesCard from './components/RequestServicesCard';
import BlogSnippets from './components/BlogSnippets';
import TextImageSection from './components/TextImageSection';

const StyledContainer = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(8, 0),
}));

const integrations = [
    "ERP systems (e.g., SAP, Oracle, Microsoft Dynamics)",
    "CRM platforms (e.g., Salesforce, HubSpot, Zoho)",
    "Marketing automation tools (e.g., Marketo, Pardot, Eloqua)",
    "Payment gateways (e.g., Stripe, PayPal, Authorize.net)",
    "Shipping and logistics providers (e.g., FedEx, UPS, DHL)",
    "Inventory management systems",
    "Accounting software (e.g., QuickBooks, Xero)",
];

const DataIntegrationsPage = () => {
    const LPtheme = createTheme(getLPTheme('dark'));

    React.useEffect(() => {
        logPageView('Data Integrations Page');
        window.scrollTo(0, 0);
    }, []);

    return (
        <ThemeProvider theme={LPtheme}>
            <Helmet>
                <title>Data Integrations - Sinteli</title>
                <meta name="description" content="Seamlessly integrate your e-commerce platform with various data sources and systems, such as ERP, CRM, and marketing automation tools. We ensure smooth data flow and synchronization across your entire ecosystem." />
                <meta name="keywords" content="data integrations, e-commerce, ERP, CRM, marketing automation" />
            </Helmet>

            <CommonAppBar mode={'dark'} />

            <CssBaseline />
            <Container sx={{ pb: 8 }}>
                <CommonAppBar mode={'dark'} />
                <StyledContainer sx={{ mb: 8, mx: 0, width: '100%' }}>
                    <CardMedia
                        component="img"
                        image="/article-2-banner copy.png"
                        alt="Data Integrations"
                        sx={{
                            objectFit: 'cover',
                            width: '100%',
                            objectPosition: '0 0',
                            height: '600px',
                            mb: 4,
                        }}
                    />

                    <Typography variant="h2" component="h1" gutterBottom>
                        Data Integrations
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Seamlessly integrate your e-commerce platform with various data sources and systems, such as ERP, CRM, and marketing automation tools. We ensure smooth data flow and synchronization across your entire ecosystem, enabling you to streamline your operations, gain valuable insights, and deliver a unified customer experience.
                    </Typography>

                    <Box sx={{ mt: 8, mb: 8 }}>
                        <Grid container spacing={2.5}>
                            {integrations.map((integration, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Stack
                                        direction="column"
                                        component={Card}
                                        spacing={1}
                                        useFlexGap
                                        sx={{
                                            color: 'inherit',
                                            px: 3,
                                            py: 1,
                                            height: '100%',
                                            border: '1px solid',
                                            borderColor: 'hsla(220, 25%, 25%, .3)',
                                            background: 'transparent',
                                            backgroundColor: 'grey.900',
                                            boxShadow: 'none',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            alignContent: 'center',
                                        }}
                                    >
                                        <div>
                                            <Typography gutterBottom sx={{ fontWeight: 'medium' }} variant='h6'>
                                                {integration}
                                            </Typography>
                                        </div>
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    <Divider sx={{ my: 4 }} />

                    <TextImageSection
                        title='Streamlined Operations'
                        description="By integrating your e-commerce platform with your ERP, CRM, and other critical systems, we help you streamline your operations and eliminate data silos. Our integration solutions enable real-time data synchronization, ensuring that your inventory levels, customer information, and order details are always up to date across all systems. This leads to improved efficiency, reduced manual errors, and faster order processing."
                        image="/data_integrations.svg"
                        textOnLeft={false}
                    />

                    <TextImageSection
                        title='Enhanced Customer Experience'
                        description="Data integrations play a crucial role in delivering a seamless and personalized customer experience. By connecting your e-commerce platform with your CRM and marketing automation tools, you can gain a 360-degree view of your customers. This allows you to tailor your marketing campaigns, product recommendations, and customer support based on individual preferences and behavior, resulting in higher customer satisfaction and loyalty."
                        image="/cdm-banner.png"
                    />

                    <Grid container spacing={6} sx={{ py: 6 }}>
                        <Grid item xs={12} sm={6} md={6} key={0}>
                            <Card sx={{ height: '100%' }}>
                                <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        Actionable Insights
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Integrating your e-commerce data with analytics and reporting tools enables you to gain valuable insights into your business performance. Our integration solutions help you consolidate data from multiple sources, providing a comprehensive view of your sales, customer behavior, and market trends. With these actionable insights at your fingertips, you can make data-driven decisions to optimize your strategies and drive growth.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} key={0}>
                            <Card sx={{ height: '100%' }}>
                                <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        Scalability and Flexibility
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Our data integration solutions are designed to scale with your business needs. Whether you're a small startup or a large enterprise, we can handle integrations of any complexity and volume. We use industry-standard protocols and APIs to ensure compatibility and flexibility, allowing you to easily connect new systems and adapt to changing requirements. Our team of integration experts is here to support you every step of the way.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <BlogSnippets title={'Related Blog Posts'} />
                    <RequestServicesCard services={integrations} />

                </StyledContainer>

                <Divider />
                <Footer />
            </Container>
        </ThemeProvider>
    );
};

export default DataIntegrationsPage;
