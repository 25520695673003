import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TimelineIcon from '@mui/icons-material/Timeline';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import BarChartIcon from '@mui/icons-material/BarChart';
import { logComponentHidden, logComponentVisible } from '../firebaseApp';

const items = [
  {
    icon: <PeopleAltIcon  />,
    title: 'AI-Powered Customer Segmentation',
    description:
      'Deliver personalized experiences that boost engagement and retention by targeting the right customers with the right message at the right time.',
  },
  {
    icon: <TimelineIcon  />,
    title: 'Real-Time Retention Analytics',
    description:
      'Make informed decisions quickly with real-time data, ensuring your strategies are always aligned with current customer behavior.',
  },
  {
    icon: <TrendingDownIcon />,
    title: 'Churn Prediction Models',
    description:
      'Reduce churn and increase customer loyalty by identifying at-risk customers early and addressing their needs before it\'s too late.',
  },
  {
    icon: <AccountBalanceWalletIcon  />,
    title: 'Customer Lifetime Value (CLV) Tracking',
    description:
      'Boost your bottom line by prioritizing high-value customers and tailoring your strategies to maximize their lifetime value.',
  },
  {
    icon: <EmojiEventsIcon  />,
    title: 'Loyalty Program Analytics',
    description:
      'Increase the effectiveness of your loyalty programs by understanding what drives participation and how to keep your customers coming back.',
  },
  {
    icon: <BarChartIcon  />,
    title: 'Engagement Score Tracking',
    description:
      'Enhance customer satisfaction and loyalty by understanding how and where your customers engage with your brand, and optimizing these interactions.',
  },
];


export default function Highlights() {
  const componentRef = React.useRef(null);
  const visibleTimestampRef = React.useRef(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          visibleTimestampRef.current = new Date().getTime();
          logComponentVisible('Highlights Component');
        } else {
          const hiddenTimestamp = new Date().getTime();
          const durationMs = hiddenTimestamp - visibleTimestampRef.current;
          logComponentHidden('Highlights Component', durationMs);
        }
      });
    });

    const currentRef = componentRef.current; // Copying componentRef.current to a variable

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            Built for Growth
          </Typography>
          {/* <Typography variant="body1" sx={{ color: 'grey.400' }}>
            Designed with scalability in mind, Sinteli is the perfect solution for businesses aiming to expand their ecommerce operations. Our platform grows with you, adapting to your evolving needs and helping you achieve sustained success.          </Typography> */}
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  color: 'inherit',
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'hsla(220, 25%, 25%, .3)',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                  boxShadow: 'none',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom sx={{ fontWeight: 'medium' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
