// src/components/BlogContainer.js
import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { Divider } from '@mui/material';
import { Helmet } from 'react-helmet';
import { logPageView } from '../../firebaseApp';
import getLPTheme from '../../getLPTheme';
import Footer from '../../components/Footer';
import CommonAppBar from '../../components/CommonAppBar';
import { useLocation } from 'react-router-dom';
import CommerceServiceCTA from '../../components/CommerceServiceCTA';

const StyledContainer = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(8, 0),
}));

const BannerContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    ...theme.applyStyles('dark', {
        boxShadow: '0 0 24px 12px hsla(267, 100%, 35%, 0.2)',
        outlineColor: 'hsla(210, 100%, 80%, 0.1)',
    }),
}));

const BannerImage = styled('img')({
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    borderRadius: 8,
});

const BlogContainer = () => {
    const location = useLocation();
    const blog = location.state?.blog;
    const LPtheme = createTheme(getLPTheme('dark'));

    React.useEffect(() => {
        if (blog && blog.headline) {
            logPageView(`${blog.headline} Page`);
        }
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, [blog]);

    return (
        <ThemeProvider theme={LPtheme}>
            <Helmet>
                <title>{blog?.headline || 'Blog'}</title>
                <meta name="description" content={blog.description} />
                <meta name="keywords" content={blog.keywords} />
            </Helmet>

            <CssBaseline />
            <CommonAppBar mode={'dark'} />
            <StyledContainer maxWidth="md" sx={{ mt: 10, px: 3 }}>
                <BannerContainer>
                    <BannerImage src={blog.image} alt={blog.headline} />
                </BannerContainer>

                <Typography variant="body2" paragraph>
                    {new Date(blog.date_published * 1000).toLocaleDateString()}
                </Typography>
                <Typography variant="h1" component="h1" gutterBottom sx={{ color: 'white' }}>
                    {blog.headline}
                </Typography>

                <Divider
                    sx={{
                        backgroundColor: 'white',
                        width: '100%',
                        height: 1,
                        marginBottom: 2,
                    }}
                />

                <div dangerouslySetInnerHTML={{ __html: blog.html_body }} />

            </StyledContainer>

            <Box sx={{
                bgcolor: 'background.default',
                alignItems: 'center',
                alignContent: 'center',
                direction: 'column',
                alignSelf: 'center',
                justifySelf: 'center',
                justifyItems: 'center',
                justifyContent: 'center',

            }}>
                <CommerceServiceCTA />
                <Divider />
                <Footer />
            </Box>
            <script type="application/ld+json">
                {`
          {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": "${blog.headline}",
            "image": "https://49df7ff2a8128beafb1757270788aff7.r2.cloudflarestorage.com/sinteli-landing-assets/${blog.image}",
            "author": {
              "@type": "Person",
              "name": "${blog.author}"
            },
            "datePublished": "${blog.date_published}",
            "dateModified": "${blog.date_modified}",
            "publisher": {
              "@type": "Organization",
              "name": "${blog.publisher}",
              "logo": {
                "@type": "ImageObject",
                "url": "https://sinteli.com/logo.png"
              }
            },
            "description": "${blog.description}"
          }
        `}
            </script>
        </ThemeProvider>
    );
};

export default BlogContainer;
