import * as React from 'react';
import { Typography, Container, Box, Divider, Grid, styled, CssBaseline, Stack, Card, CardMedia, CardContent } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import { logPageView } from '../../firebaseApp';
import getLPTheme from '../../getLPTheme';
import CommonAppBar from '../../components/CommonAppBar';
import Footer from '../../components/Footer';
import TextImageSection from '../services/components/TextImageSection';
import RequestServicesCard from '../services/components/RequestServicesCard';

const StyledContainer = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(8, 0),
}));

const features = [
    "Centralized product information management",
    "Streamlined product data enrichment",
    "Consistent product data across all channels",
    "Improved data quality and accuracy",
    "Enhanced product discoverability",
    "Faster time-to-market for new products",
    "Seamless integration with e-commerce platforms",
];

const products = [
    'Product Information Management',
    'Order Management System',
    'Customer Data Platform',
];

const PIMPage = () => {
    const LPtheme = createTheme(getLPTheme('dark'));

    React.useEffect(() => {
        logPageView('PIM Page');
        window.scrollTo(0, 0);
    }, []);

    return (
        <ThemeProvider theme={LPtheme}>
            <Helmet>
                <title>Product Information Management (PIM) - Sinteli</title>
                <meta name="description" content="Streamline your product information management with our powerful PIM solution. Centralize product data, ensure consistency across channels, and improve data quality for enhanced product discoverability and faster time-to-market." />
                <meta name="keywords" content="product information management, PIM, product data, e-commerce, consistency, data quality" />
            </Helmet>

            <CommonAppBar mode={'dark'} />

            <CssBaseline />
            <Container sx={{ pb: 8 }}>
                <CommonAppBar mode={'dark'} />
                <StyledContainer sx={{ mt: 4, mb: 8, mx: 0, width: '100%' }}>

                    <Typography
                        variant="h2"
                        component="h1"
                        sx={{
                            my: 8,
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: 'center',
                            fontSize: 'clamp(3rem, 10vw, 1.5rem)',
                            textAlign: 'center',
                        }}
                    >Product Information Management (PIM)
                    </Typography>


                    <CardMedia
                        component="img"
                        image="/product_info_management2.svg"
                        alt="Product Information Management (PIM)"
                        sx={{
                            objectFit: 'contain',
                            width: '100%',
                            objectPosition: 'center',
                            height: '600px',
                            mb: 4,
                        }}
                    />
                    <Typography variant="body1" paragraph>
                        Streamline your product information management with our powerful PIM solution. Centralize product data, ensure consistency across channels, and improve data quality for enhanced product discoverability and faster time-to-market. Our PIM system empowers you to efficiently manage and distribute product information, driving better customer experiences and increased sales.
                    </Typography>
                    <Box sx={{ mt: 8, mb: 8 }}>
                        <Grid container spacing={2.5}>
                            {features.map((feature, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Stack
                                        direction="column"
                                        component={Card}
                                        spacing={1}
                                        useFlexGap
                                        sx={{
                                            color: 'inherit',
                                            px: 3,
                                            py: 1,
                                            height: '100%',
                                            border: '1px solid',
                                            borderColor: 'hsla(220, 25%, 25%, .3)',
                                            background: 'transparent',
                                            backgroundColor: 'grey.900',
                                            boxShadow: 'none',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            alignContent: 'center',
                                        }}
                                    >
                                        <div>
                                            <Typography gutterBottom sx={{ fontWeight: 'medium' }} variant='h6'>
                                                {feature}
                                            </Typography>
                                        </div>
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    <Divider sx={{ my: 4 }} />

                    <TextImageSection
                        title='Centralized Product Data'
                        description="Our PIM solution serves as a single source of truth for all your product information. Centralize product data from various sources, including ERP, PLM, and supplier systems, into a unified repository. Ensure data consistency, accuracy, and completeness across your organization, enabling seamless collaboration and efficient product management."
                        image="/article-2-banner copy.png"
                        textOnLeft={false}
                    />

                    <TextImageSection
                        title='Streamlined Product Enrichment'
                        description="Enrich your product data with ease using our intuitive PIM interface. Add detailed product descriptions, specifications, images, videos, and other relevant attributes to create compelling product experiences. Streamline the product enrichment process, reducing manual effort and ensuring high-quality product content across all touchpoints."
                        image="/article-9-banner.png"
                    />

                    <Grid container spacing={6} sx={{ py: 6, mb: 12 }}>
                        <Grid item xs={12} sm={6} md={6} key={0}>
                            <Card sx={{ height: '100%' }}>
                                <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        Omnichannel Consistency
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Deliver consistent product information across all your sales channels, including e-commerce websites, marketplaces, and print catalogs. Our PIM system ensures that your customers have access to accurate and up-to-date product data, regardless of the channel they engage with. Maintain a unified brand experience and build customer trust through consistent product messaging.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} key={0}>
                            <Card sx={{ height: '100%' }}>
                                <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        Seamless E-Commerce Integration
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Integrate our PIM solution seamlessly with your e-commerce platform to streamline product data management. Automatically sync product information, including prices, inventory levels, and product attributes, between your PIM and e-commerce system. Ensure that your online store always displays accurate and up-to-date product information, enhancing the customer experience and driving conversions.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <RequestServicesCard services={products} />

                </StyledContainer>

                <Divider />
                <Footer />
            </Container>
        </ThemeProvider>
    );
};

export default PIMPage;
