import React, { useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Alert, CircularProgress, List, ListItem, ListItemText, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { logAbandonedForm, logError, logFormSubmission, logPageView, functions } from '../firebaseApp';
import CommonAppBar from '../components/CommonAppBar';
import Footer from '../components/Footer';
import debounce from 'lodash/debounce';
import { httpsCallable } from 'firebase/functions';

const CustomInput = ({ label, value, onChange, error, helperText, required, type = "text", multiline = false, rows = 1, placeholder = "", select = false, children }) => {
    return (
        <div style={{ marginBottom: '1rem' }}>
            <label style={{
                display: 'block',
                marginBottom: '0.5rem',
                color: '#fff',
                fontSize: '0.9rem'
            }}>
                {label} {required && <span style={{ color: '#ff6b6b' }}>*</span>}
            </label>
            {select ? (
                <select
                    value={value}
                    onChange={onChange}
                    style={{
                        width: '100%',
                        padding: '0.75rem',
                        borderRadius: '8px',
                        border: error ? '2px solid #ff6b6b' : '1px solid #000',
                        fontSize: '1rem',
                        backgroundColor: '#0a192f',
                        color: '#fff',
                        outline: 'none',
                        transition: 'all 0.2s',
                        background: 'linear-gradient(145deg, #0a192f, #112240)',
                        boxShadow: '0 4px 15px rgba(0,0,0,0.2)',
                        '&:focus': {
                            borderColor: '#B22CB3',
                            boxShadow: '0 0 0 2px rgba(178,44,179,0.2)'
                        }
                    }}
                >
                    {children}
                </select>
            ) : (
                multiline ? (
                    <textarea
                        value={value}
                        onChange={onChange}
                        rows={rows}
                        placeholder={placeholder}
                        style={{
                            width: '100%',
                            padding: '0.75rem',
                            borderRadius: '8px',
                            border: error ? '2px solid #ff6b6b' : '1px solid #000',
                            fontSize: '1rem',
                            fontFamily: 'inherit',
                            resize: 'vertical',
                            outline: 'none',
                            transition: 'all 0.2s',
                            backgroundColor: '#0a192f',
                            color: '#fff',
                            background: 'linear-gradient(145deg, #0a192f, #112240)',
                            boxShadow: '0 4px 15px rgba(0,0,0,0.2)',
                        }}
                    />
                ) : (
                    <input
                        type={type}
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        style={{
                            width: '100%',
                            padding: '0.75rem',
                            borderRadius: '8px',
                            border: error ? '2px solid #ff6b6b' : '1px solid #000',
                            fontSize: '1rem',
                            outline: 'none',
                            transition: 'all 0.2s',
                            backgroundColor: '#0a192f',
                            color: '#fff',
                            background: 'linear-gradient(145deg, #0a192f, #112240)',
                            boxShadow: '0 4px 15px rgba(0,0,0,0.2)',
                            '&:focus': {
                                borderColor: '#B22CB3',
                                boxShadow: '0 0 0 2px rgba(178,44,179,0.2)'
                            }
                        }}
                    />
                )
            )}
            {error && helperText && (
                <span style={{
                    color: '#ff6b6b',
                    fontSize: '0.75rem',
                    marginTop: '0.25rem',
                    display: 'block'
                }}>
                    {helperText}
                </span>
            )}
        </div>
    );
};

const MerchantApplicationPage = () => {
    const location = useLocation();
    const [activeStep, setActiveStep] = useState(() => {
        const savedStep = localStorage.getItem('merchantApplicationStep');
        return savedStep ? parseInt(savedStep) : 0;
    });
    const [formData, setFormData] = useState(() => {
        const savedFormData = localStorage.getItem('merchantApplicationData');
        const sessionId = localStorage.getItem('formSessionId') || Math.random().toString(36).substring(2);
        localStorage.setItem('formSessionId', sessionId);
        console.log(sessionId);
        
        return savedFormData ? JSON.parse(savedFormData) : {
            businessName: '',
            website: '',
            contactName: '',
            email: location.state?.email || '',
            phone: '',
            industry: '',
            additionalInformation: '',
            sessionId: sessionId,
            marketingConsent: false,
            privacyPolicyAccepted: false
        };
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [submissionSuccess, setSubmissionSuccess] = useState(false);


    useEffect(() => {
        logPageView('Merchant Application Page');
    }, []);

    useEffect(() => {
        localStorage.setItem('merchantApplicationStep', activeStep);
    }, [activeStep]);

    useEffect(() => {
        localStorage.setItem('merchantApplicationData', JSON.stringify(formData));
    }, [formData]);

    const saveFormData = useCallback(async (data) => {
        try {
            const response = await fetch('https://forms.sinteli.com/api/save-draft', {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    businessName: data.businessName,
                    website: data.website,
                    contactName: data.contactName,
                    email: data.email,
                    phone: data.phone,
                    industry: data.industry,
                    additionalInformation: data.additionalInformation,
                    sessionId: data.sessionId,
                    marketingConsent: data.marketingConsent,
                    privacyPolicyAccepted: data.privacyPolicyAccepted
                })
            });

            console.log(data.sessionId);

            const result = await response.json();

            if (result.sessionId && !data.sessionId) {
                setFormData(prev => ({ ...prev, sessionId: result.sessionId }));
                localStorage.setItem('formSessionId', result.sessionId);
            }
        } catch (error) {
            logError('Form Auto-Save', error.message);
        }
    }, []);

    // Save draft when user leaves the page
    useEffect(() => {
        const handleBeforeUnload = () => {
            if (formData.sessionId || Object.values(formData).some(val => val)) {
                saveFormData(formData);
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            // Also save when component unmounts
            if (formData.sessionId || Object.values(formData).some(val => val)) {
                saveFormData(formData);
            }
        };
    }, []);

    useEffect(() => {
        if (submissionSuccess) {
            localStorage.removeItem('merchantApplicationStep');
            localStorage.removeItem('merchantApplicationData');
            localStorage.removeItem('formSessionId');
        }
    }, [submissionSuccess]);

    const handleNext = () => {
        const newErrors = validateStep(activeStep);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setErrors({});
    };

    const handleChange = (event) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setFormData({
            ...formData,
            [event.target.name]: value,
        });
    };

    const validateStep = (step) => {
        const newErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^\+?[\d\s-]{10,}$/;

        switch (step) {
            case 0:
                if (!formData.businessName) newErrors.businessName = 'Business Name is required';
                if (!formData.contactName) newErrors.contactName = 'Contact Name is required';
                if (!formData.email) {
                    newErrors.email = 'Email is required';
                } else if (!emailRegex.test(formData.email)) {
                    newErrors.email = 'Invalid email address';
                }
                if (formData.phone && !phoneRegex.test(formData.phone)) {
                    newErrors.phone = 'Invalid phone number';
                }
                break;
            case 1:
                if (!formData.industry) newErrors.industry = 'Industry is required';
                break;
            case 2:
                if (!formData.privacyPolicyAccepted) newErrors.privacyPolicyAccepted = 'You must accept the Privacy Policy';
                break;
            default:
                break;
        }

        return newErrors;
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const newErrors = validateStep(activeStep);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            setIsLoading(true);
            setSubmissionSuccess(false);

            try {
                // Get UTM parameters from URL
                const urlParams = new URLSearchParams(window.location.search);
                const utmSource = urlParams.get('utm_source');
                const utmMedium = urlParams.get('utm_medium'); 
                const utmCampaign = urlParams.get('utm_campaign');

                const response = await fetch('https://forms.sinteli.com/api/submit-form', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        businessName: formData.businessName,
                        website: formData.website,
                        contactName: formData.contactName,
                        email: formData.email,
                        phone: formData.phone,
                        industry: formData.industry,
                        additionalInformation: formData.additionalInformation,
                        sessionId: formData.sessionId,
                        utmSource,
                        utmMedium,
                        utmCampaign,
                        referrerUrl: document.referrer,
                        userAgent: navigator.userAgent,
                        marketingConsent: formData.marketingConsent,
                        privacyPolicyAccepted: formData.privacyPolicyAccepted
                    })
                });

                const result = await response.json();

                if (result.message === 'Form submitted successfully') {
                    setSubmissionSuccess(true);
                    logFormSubmission('Merchant Application', formData);
                }
            } catch (error) {
                logError('Merchant Application Form', error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const steps = ['Basic Information', 'Business Details', 'Review'];

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: { xs: 1.5, sm: 2 }
                    }}>
                        <CustomInput
                            required
                            label="Business Name"
                            value={formData.businessName}
                            onChange={(e) => handleChange({ target: { name: 'businessName', value: e.target.value } })}
                            error={!!errors.businessName}
                            helperText={errors.businessName}
                        />
                        <CustomInput
                            label="Website"
                            value={formData.website}
                            onChange={(e) => handleChange({ target: { name: 'website', value: e.target.value } })}
                            error={!!errors.website}
                            helperText={errors.website}
                        />
                        <CustomInput
                            required
                            label="Contact Name"
                            value={formData.contactName}
                            onChange={(e) => handleChange({ target: { name: 'contactName', value: e.target.value } })}
                            error={!!errors.contactName}
                            helperText={errors.contactName}
                        />
                        <CustomInput
                            required
                            label="Email"
                            type="email"
                            value={formData.email}
                            onChange={(e) => handleChange({ target: { name: 'email', value: e.target.value } })}
                            error={!!errors.email}
                            helperText={errors.email}
                        />
                        <CustomInput
                            label="Phone Number (Optional)"
                            value={formData.phone}
                            onChange={(e) => handleChange({ target: { name: 'phone', value: e.target.value } })}
                            error={!!errors.phone}
                            helperText={errors.phone}
                        />
                    </Box>
                );
            case 1:
                return (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: { xs: 1.5, sm: 2 }
                    }}>
                        <CustomInput
                            required
                            label="Industry"
                            value={formData.industry}
                            onChange={(e) => handleChange({ target: { name: 'industry', value: e.target.value } })}
                            error={!!errors.industry}
                            helperText={errors.industry}
                            select
                            placeholder="Help us understand your business sector to provide relevant support and features"
                        >
                            <option value="">Select an industry</option>
                            <option value="fashion">Fashion</option>
                            <option value="beauty">Beauty</option>
                            <option value="apparel">Apparel</option>
                            <option value="electronics">Electronics</option>
                            <option value="home">Home & Living</option>
                            <option value="health">Health & Wellness</option>
                            <option value="food">Food & Beverage</option>
                            <option value="sports">Sports & Outdoors</option>
                            <option value="toys">Toys & Games</option>
                            <option value="others">Others</option>
                        </CustomInput>
                        <CustomInput
                            label="Additional Information"
                            value={formData.additionalInformation}
                            onChange={(e) => handleChange({ target: { name: 'additionalInformation', value: e.target.value } })}
                            error={!!errors.additionalInformation}
                            helperText={errors.additionalInformation}
                            multiline
                            rows={4}
                            placeholder="Please share any additional information about your business that would help us understand it better"
                        />
                    </Box>
                );
            case 2:
                return (
                    <Box sx={{
                        bgcolor: '#1a1a1a',
                        borderRadius: 2,
                        p: { xs: 2, sm: 3 }
                    }}>
                        <List sx={{
                            '& .MuiListItem-root': {
                                flexDirection: { xs: 'column', sm: 'row' },
                                alignItems: { xs: 'flex-start', sm: 'center' },
                                py: { xs: 1.5, sm: 1 },
                                color: '#fff'
                            }
                        }}>
                            <ListItem>
                                <ListItemText primary="Business Name" secondary={formData.businessName} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Website" secondary={formData.website} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Contact Name" secondary={formData.contactName} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Email" secondary={formData.email} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Phone" secondary={formData.phone} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Industry" secondary={formData.industry} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Additional Information" secondary={formData.additionalInformation} />
                            </ListItem>
                        </List>
                        <Box sx={{ mt: 3 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.marketingConsent}
                                        onChange={handleChange}
                                        name="marketingConsent"
                                        sx={{
                                            color: '#fff',
                                            '&.Mui-checked': {
                                                color: '#c1fe72',
                                            },
                                        }}
                                    />
                                }
                                label={
                                    <Typography variant="body2" sx={{ color: '#fff' }}>
                                        I agree to receive communications from Sinteli about my application
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.privacyPolicyAccepted}
                                        onChange={handleChange}
                                        name="privacyPolicyAccepted"
                                        sx={{
                                            color: '#fff',
                                            '&.Mui-checked': {
                                                color: '#c1fe72',
                                            },
                                        }}
                                    />
                                }
                                label={
                                    <Typography variant="body2" sx={{ color: '#fff' }}>
                                        I accept the <a href="/privacy" target="_blank" style={{ color: '#c1fe72' }}>Privacy Policy</a> *
                                    </Typography>
                                }
                            />
                            {errors.privacyPolicyAccepted && (
                                <Typography variant="caption" sx={{ color: '#ff6b6b', display: 'block', mt: 1 }}>
                                    {errors.privacyPolicyAccepted}
                                </Typography>
                            )}
                        </Box>
                        <Typography variant="body2" sx={{ mt: 2, color: '#fff' }}>
                            Please review your information carefully. After submitting, our team will review your application and contact you within 2-3 business days.
                        </Typography>
                    </Box>
                );
            default:
                return 'Unknown step';
        }
    };

    return (
        <Container sx={{
            py: { xs: 4, sm: 8 },
            minHeight: '100vh',
            bgcolor: 'transparent'
        }}>
            <CommonAppBar mode={'dark'} />
            {submissionSuccess ? (
                <Box sx={{
                    mt: { xs: 4, sm: 8 },
                    p: { xs: 2, sm: 4 },
                    bgcolor: 'background.paper',
                    borderRadius: 2
                }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Application Submitted Successfully!
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Thank you for your interest in joining our platform. Our team will review your application and contact you within 2-3 business days.
                    </Typography>
                    <Button
                        variant="contained"
                        href="/"
                        sx={{
                            mt: 4,
                            bgcolor: 'primary.main',
                            '&:hover': {
                                bgcolor: 'primary.dark'
                            }
                        }}
                    >
                        Return to Homepage
                    </Button>
                </Box>
            ) : (
                <Box sx={{
                    mt: 8,
                    p: 4,
                    bgcolor: 'background.paper',
                    borderRadius: 2
                }}>
                    <Typography
                        variant="h4"
                        component="h1"
                        gutterBottom
                        align="center"
                        sx={{
                            fontSize: { xs: '1.5rem', sm: '2.125rem' }
                        }}
                    >
                        Merchant Application
                    </Typography>
                    <Typography variant="body1" paragraph align="center" sx={{ mb: 4 }}>
                        Join our platform and reach more customers. Please fill out the form below to apply.
                    </Typography>
                    <Stepper
                        activeStep={activeStep}
                        alternativeLabel
                        sx={{
                            mb: { xs: 2, sm: 4 },
                            '& .MuiStepLabel-label': {
                                fontSize: { xs: '0.75rem', sm: '0.875rem' },
                                color: '#fff'
                            },
                            '& .MuiStepIcon-root': {
                                fontSize: { xs: '1.25rem', sm: '1.5rem' },
                                color: '#444'
                            },
                            '& .MuiStepIcon-root.Mui-active': {
                                color: '#B22CB3',
                            },
                            '& .MuiStepIcon-root.Mui-completed': {
                                color: '#c1fe72',
                            }
                        }}
                    >
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Box component="form" noValidate sx={{ mt: 1 }}>
                        {getStepContent(activeStep)}
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mt: 3,
                            gap: 1,
                        }}>
                            {activeStep !== 0 && (
                                <Button
                                    onClick={handleBack}
                                    sx={{
                                        color: '#fff',
                                        borderColor: '#444',
                                        '&:hover': {
                                            borderColor: '#666'
                                        }
                                    }}
                                >
                                    Back
                                </Button>
                            )}
                            {activeStep === steps.length - 1 ? (
                                <Button
                                    variant="contained"
                                    onClick={handleSubmit}
                                    disabled={isLoading}
                                    sx={{
                                        background: 'linear-gradient(90deg, #B22CB3 0%, #c1fe72 100%)',
                                        color: '#000',
                                        fontWeight: 'bold',
                                        '&:hover': {
                                            color: '#fff',
                                            background: 'linear-gradient(90deg, #B22CB3 20%, #c1fe72 120%)'
                                        }
                                    }}
                                >
                                    {isLoading ? <CircularProgress size={24} /> : 'Submit Application'}
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    onClick={handleNext}
                                    sx={{
                                        background: 'linear-gradient(90deg, #B22CB3 0%, #c1fe72 100%)',
                                        color: '#000',
                                        fontWeight: 'bold',
                                        borderRadius: 2,
                                        border: 'none',
                                        padding: '10px 40px',
                                        fontSize: '1rem',
                                        textTransform: 'none',
                                        '&:hover': {
                                            color: '#fff',
                                            background: 'linear-gradient(90deg, #B22CB3 20%, #c1fe72 120%)',
                                        },
                                    }}
                                >
                                    Next
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Box>
            )}
            <Footer />
        </Container>
    );
};

export default MerchantApplicationPage;