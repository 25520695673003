import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Alert, CircularProgress, List, ListItem, ListItemText } from '@mui/material';
import CommonAppBar from '../components/CommonAppBar';
import Footer from '../components/Footer';
import { logFormSubmission, logError, logPageView } from '../firebaseApp';

const CustomInput = ({ label, value, onChange, error, helperText, required, type = "text", multiline = false, rows = 1 }) => {
    return (
        <div style={{ marginBottom: '1rem' }}>
            <label style={{
                display: 'block',
                marginBottom: '0.5rem',
                color: '#fff',
                fontSize: '0.9rem'
            }}>
                {label} {required && <span style={{ color: '#ff6b6b' }}>*</span>}
            </label>
            {multiline ? (
                <textarea
                    value={value}
                    onChange={onChange}
                    rows={rows}
                    style={{
                        width: '100%',
                        padding: '0.75rem',
                        borderRadius: '8px',
                        border: error ? '2px solid #ff6b6b' : '1px solid #000',
                        fontSize: '1rem',
                        backgroundColor: '#0a192f',
                        color: '#fff',
                        outline: 'none',
                        transition: 'all 0.2s',
                        background: 'linear-gradient(145deg, #0a192f, #112240)',
                        boxShadow: '0 4px 15px rgba(0,0,0,0.2)'
                    }}
                />
            ) : (
                <input
                    type={type}
                    value={value}
                    onChange={onChange}
                    style={{
                        width: '100%',
                        padding: '0.75rem',
                        borderRadius: '8px',
                        border: error ? '2px solid #ff6b6b' : '1px solid #000',
                        fontSize: '1rem',
                        backgroundColor: '#0a192f',
                        color: '#fff',
                        outline: 'none',
                        transition: 'all 0.2s',
                        background: 'linear-gradient(145deg, #0a192f, #112240)',
                        boxShadow: '0 4px 15px rgba(0,0,0,0.2)'
                    }}
                />
            )}
            {error && (
                <span style={{
                    color: '#ff6b6b',
                    fontSize: '0.75rem',
                    marginTop: '0.25rem',
                    display: 'block'
                }}>
                    {helperText}
                </span>
            )}
        </div>
    );
};

const DeleteAccountPage = () => {
    const [formData, setFormData] = useState({
        email: '',
        reason: '',
        confirmationText: ''
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [submissionSuccess, setSubmissionSuccess] = useState(false);

    React.useEffect(() => {
        logPageView('Delete Account Page');
    }, []);

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const validateForm = () => {
        const newErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!emailRegex.test(formData.email)) {
            newErrors.email = 'Invalid email address';
        }

        if (!formData.confirmationText) {
            newErrors.confirmationText = 'Please type "DELETE" to confirm';
        } else if (formData.confirmationText !== 'DELETE') {
            newErrors.confirmationText = 'Please type "DELETE" exactly as shown';
        }

        return newErrors;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const newErrors = validateForm();
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            setIsLoading(true);

            try {
                const response = await fetch('https://privacy.sinteli.com/api/delete-request', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: formData.email,
                        reason: formData.reason,
                        requestDate: new Date().toISOString(),
                        userAgent: navigator.userAgent,
                        referrerUrl: document.referrer,
                        confirmationText: formData.confirmationText
                    })
                });

                if (response.ok) {
                    setSubmissionSuccess(true);
                    logFormSubmission('Account Deletion Request', {
                        email: formData.email,
                        reason: formData.reason
                    });

                    // Clear form data
                    setFormData({
                        email: '',
                        reason: '',
                        confirmationText: ''
                    });
                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Submission failed');
                }
            } catch (error) {
                logError('Account Deletion Request', error.message);
                setErrors({
                    submit: 'Failed to submit request. Please try again or contact support at support@sinteli.com'
                });
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <Container sx={{ py: 8, minHeight: '100vh' }}>
            <CommonAppBar mode="dark" />
            <Box sx={{ maxWidth: 'md', mx: 'auto', mt: 8, p: 4 }}>
                {submissionSuccess ? (
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="h4" component="h1" gutterBottom>
                            Request Received
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We have received your account deletion request. Our team will process it within 48 hours and send you a confirmation email once completed.
                        </Typography>
                        <Button variant="contained" href="/" sx={{ mt: 4 }}>
                            Return to Homepage
                        </Button>
                    </Box>
                ) : (
                    <>
                        <Typography variant="h4" component="h1" gutterBottom align="center">
                            Request Account Deletion
                        </Typography>
                        <Typography variant="body1" paragraph align="center" sx={{ mb: 4 }}>
                            We're sorry to see you go. Please follow the steps below to request account deletion.
                        </Typography>

                        <List sx={{ mb: 4 }}>
                            <ListItem>
                                <ListItemText
                                    primary="1. Verify Your Account"
                                    secondary="Enter the email address associated with your account"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="2. Provide Feedback (Optional)"
                                    secondary="Let us know why you're leaving to help us improve our services"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="3. Confirm Deletion"
                                    secondary='Type "DELETE" to confirm your request'
                                />
                            </ListItem>
                        </List>

                        <Box component="form" onSubmit={handleSubmit} noValidate>
                            <CustomInput
                                required
                                label="Email Address"
                                value={formData.email}
                                onChange={(e) => handleChange({ target: { name: 'email', value: e.target.value }})}
                                error={!!errors.email}
                                helperText={errors.email}
                                type="email"
                            />

                            <CustomInput
                                label="Reason for Leaving (Optional)"
                                value={formData.reason}
                                onChange={(e) => handleChange({ target: { name: 'reason', value: e.target.value }})}
                                multiline
                                rows={4}
                            />

                            <CustomInput
                                required
                                label='Type "DELETE" to Confirm'
                                value={formData.confirmationText}
                                onChange={(e) => handleChange({ target: { name: 'confirmationText', value: e.target.value }})}
                                error={!!errors.confirmationText}
                                helperText={errors.confirmationText}
                            />

                            {errors.submit && (
                                <Alert severity="error" sx={{ mt: 2 }}>
                                    {errors.submit}
                                </Alert>
                            )}

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={isLoading}
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    bgcolor: 'error.main',
                                    '&:hover': {
                                        bgcolor: 'error.dark'
                                    }
                                }}
                            >
                                {isLoading ? <CircularProgress size={24} /> : 'Request Account Deletion'}
                            </Button>

                            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                                Need help? Contact us at{' '}
                                <a href="mailto:support@sinteli.com" style={{ color: '#B22CB3' }}>
                                    support@sinteli.com
                                </a>
                            </Typography>
                        </Box>
                    </>
                )}
            </Box>
            <Footer />
        </Container>
    );
};

export default DeleteAccountPage;