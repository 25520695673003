import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { Card, Divider, CircularProgress } from '@mui/material';
import { logFormSubmission } from '../firebaseApp';

const CommerceServiceCTA = () => {
    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState('');
    const [loadingEmailSubmit, setLoadingEmailSubmit] = React.useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        setEmailError('');
    };

    const handleStartNow = async () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email.trim() === '') {
            setEmailError('Please enter your email address');
        } else if (!emailRegex.test(email)) {
            setEmailError('Invalid email address');
        } else {
            try {
                setLoadingEmailSubmit(true);
                const response = await fetch('https://api.airtable.com/v0/appbw9FhuHO7M735N/tbl7WoukH2uYDkXin', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${process.env.REACT_APP_AIRTABLE_TOKEN}`,
                    },
                    body: JSON.stringify({
                        records: [
                            {
                                fields: {
                                    Email: email,
                                    Source: 'Commerce Service CTA',
                                },
                            },
                        ],
                    }),
                });

                if (response.ok) {
                    logFormSubmission('Commerce Service CTA', { email });
                    setEmail('');
                    setShowSuccessMessage(true);
                    setTimeout(() => setShowSuccessMessage(false), 5000);
                } else {
                    setEmailError('Failed to join waitlist. Please try again.');
                }
            } catch (error) {
                setEmailError('An error occurred. Please try again.');
            } finally {
                setLoadingEmailSubmit(false);
            }
        }
    };

    return (
        <Container>
            <Card
                sx={{
                    py: { xs: 4, sm: 6 },
                    px: { xs: 4, sm: 6 },
                    mb: { xs: 4, sm: 6 },
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    borderRadius: '8px',
                    color: 'grey.100',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.10)',
                }}
            >
                <Typography
                    component="h2"
                    variant="h4"
                    sx={{ mb: 2 }}
                    textAlign={{ xs: 'center', md: 'left' }}
                >
                    Join Waitlist
                </Typography>

                <Divider />
                <Typography
                    component="h2"
                    variant="body1"
                    textAlign={{ xs: 'center', md: 'left' }}
                    sx={{
                        mt: 2,
                        textAlign: 'center',
                        width: { xs: '100%', md: '50%' },
                        mb: { xs: 2, sm: 4 }
                    }}
                >
                    Be among the first to experience a new era of digital advertising that puts control back in your hands.
                </Typography>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={1}
                    useFlexGap
                    sx={{ width: { xs: '100%', sm: 'auto' }, mt: 0 }}
                >
                    <TextField
                        id="email-commerce-cta"
                        hiddenLabel
                        size="small"
                        variant="outlined"
                        aria-label="Enter your company email address"
                        placeholder="Company email address"
                        value={email}
                        onChange={handleEmailChange}
                        error={!!emailError}
                        helperText={emailError}
                        type="email"
                        autoComplete="email"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: 2
                            }
                        }}
                    />
                    <Button
                        variant="contained"
                        onClick={handleStartNow}
                        disabled={loadingEmailSubmit}
                        sx={{
                            background: 'linear-gradient(90deg, #B22CB3 0%, #c1fe72 100%)',
                            color: 'black',
                            fontWeight: 'bold',
                            borderRadius: 2,
                            border: 'none',
                            padding: '10px 20px',
                            fontSize: '1rem',
                            textTransform: 'none',
                            '&:hover': {
                                background: 'linear-gradient(90deg, #B22CB3 20%, #c1fe72 120%)',
                            },
                        }}
                    >
                        Join Waitlist{loadingEmailSubmit && <CircularProgress size={20} />}
                    </Button>
                </Stack>
                {showSuccessMessage && (
                    <Typography
                        variant="body2"
                        sx={{
                            color: 'success.main',
                            mt: 2,
                            textAlign: 'center',
                        }}
                    >
                        Thank you for joining our waitlist! We'll be in touch soon.
                    </Typography>
                )}
                {/* <Box
                    component="img"
                    src="https://imagedelivery.net/f7ix1vCCw-qhLpZb3c_8yw/061dd0f7-b344-4fec-37e7-7088410daf00/public"
                    alt="Community Image"
                    sx={{
                        width: { xs: '50%', md: '30%' },
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: '0 0',
                    }}
                /> */}

            </Card>
        </Container>
    );
};

export default CommerceServiceCTA;
