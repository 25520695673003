import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Divider, styled } from '@mui/material';
import Footer from '../components/Footer';
import Sitemark from '../components/SitemarkIcon';
import { logPageView } from '../firebaseApp';

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  padding: theme.spacing(8, 0),
}));

const TermsPage = () => {
  React.useEffect(() => {
    logPageView('Terms Page');
  }, []);

  return (
    <Container>
      <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
        <Sitemark />
      </Box>
      <StyledContainer maxWidth="md">
        <Typography variant="body1" component="p">
          UPDATED: JUNE 16, 2024
        </Typography>
        <Typography variant="h4" component="h1" gutterBottom>
          Terms of Service
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Typography variant="body1" paragraph>
            Welcome to Sinteli! These Terms of Use ("Terms") govern your access to and use of our composable e-commerce solutions and related services (collectively, the "Services"), which are provided by HGLOW MEDIA Inc., doing business as Sinteli ("Sinteli," "we," "us," or "our").
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            1. Acceptance of Terms
          </Typography>
          <Typography variant="body1" paragraph>
            By using our Services, you agree to be bound by these Terms of Use and our Privacy Policy, incorporated herein by reference. If you do not agree to these Terms, please do not use our Services.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            2. Changes to Terms
          </Typography>
          <Typography variant="body1" paragraph>
            We may update these Terms from time to time at our discretion. We will notify you of any changes by revising the date at the top of the Terms and, in some cases, we may provide additional notice (such as adding a statement to our homepage or sending you a notification). Your continued use of the Services following the posting of changes will mean that you accept and agree to the changes.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            3. Privacy Policy
          </Typography>
          <Typography variant="body1" paragraph>
            Please refer to our Privacy Policy for information on how we collect, use, and share your information.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            4. Access and Use of the Services
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Eligibility:</strong> You must be at least 18 years old to use the Services.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>License to Use:</strong> Subject to these Terms, we grant you a limited, non-exclusive, non-transferable, and revocable license to access and use our Services for your business use.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Prohibited Activities:</strong> You may not access or use the Services for any purpose other than that for which we make the Services available. You may not use the Services to engage in any illegal activity or to violate laws in your jurisdiction.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            5. Intellectual Property Rights
          </Typography>
          <Typography variant="body1" paragraph>
            All intellectual property rights in the Services and its original content, features, and functionality are and will remain the exclusive property of Sinteli and its licensors.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            6. Links to Other Web Sites
          </Typography>
          <Typography variant="body1" paragraph>
            Our Services may contain links to third-party web sites or services that are not owned or controlled by Sinteli. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party web sites or services.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            7. Termination
          </Typography>
          <Typography variant="body1" paragraph>
            We may terminate or suspend your access to our Services immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach the Terms.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            8. Indemnification
          </Typography>
          <Typography variant="body1" paragraph>
            You agree to defend, indemnify, and hold harmless Sinteli and its licensee and licensors, and their employees, contractors, agents, officers, and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of your use of and access to the Services, or from your breach of any provision of these Terms.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            9. Limitation of Liability
          </Typography>
          <Typography variant="body1" paragraph>
            In no event shall Sinteli, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Services.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            10. Governing Law
          </Typography>
          <Typography variant="body1" paragraph>
            These Terms shall be governed and construed in accordance with the laws of Alberta, Canada, without regard to its conflict of law provisions.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            11. Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions about these Terms, please contact us at:
          </Typography>
          <Typography variant="body1" paragraph>
            HGLOW MEDIA Inc.<br />
            support@sinteli.com
          </Typography>
          <Typography variant="body1" paragraph>
            By accessing or using our Services, you acknowledge that you have read, understood, and agree to be bound by these Terms.
          </Typography>
        </Box>
      </StyledContainer>

      <Box sx={{ bgcolor: 'background.default' }}>
        <Divider />
        <Footer />
      </Box>
    </Container>
  );
};

export default TermsPage;
