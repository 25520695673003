import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Divider, styled } from '@mui/material';
import Footer from '../components/Footer';
import CommonAppBar from '../components/CommonAppBar';
import { logPageView } from '../firebaseApp';

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  padding: theme.spacing(8, 0),
}));

const CareersPage = () => {
  React.useEffect(() => {
    logPageView('Careers Page');
}, []);

  return (
    <Container sx={{ py: 8 }}>
      <CommonAppBar mode={'dark'} />
      <StyledContainer maxWidth="md" sx={{ mt: 8, mb: 8 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Careers
        </Typography>
        <Typography variant="body1" paragraph>
          Join our team and be part of a dynamic and innovative company. We offer
          competitive salaries, excellent benefits, and opportunities for growth
          and development.
        </Typography>
        <Divider sx={{ my: 4 }} />
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Sorry, there are no open positions at this time.
          </Typography>
        </Box>
      </StyledContainer>

      <Box sx={{ bgcolor: 'background.default' }}>
        <Divider />
        <Footer />
      </Box>
    </Container>
  );
};

export default CareersPage;
