import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import { logPageView } from '../firebaseApp';
import getLPTheme from '../getLPTheme';
import CommonAppBar from '../components/CommonAppBar';
import Footer from '../components/Footer';
import { Grid, Divider, Card, CardContent, CardMedia, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RequestServicesCard from './services/components/RequestServicesCard';

const StyledContainer = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(8, 0),
}));

const products = [
    {
        id: 1,
        title: 'Product Information Management',
        description: 'Our product information management (PIM) services help you manage your product catalog, improve your visibility, and drive sales.',
        image: 'pim-banner.png',
        path: '/products/product-information-management',
        objectPosition: 'center',
    },
    {
        id: 2,
        title: 'Order Management System',
        description: 'Our order management system (OMS) services help you manage your orders, shipments, and customer service.',
        image: 'oms-banner.png',
        path: '/products/order-management-system',
    },
    {
        id: 3,
        title: 'Customer Data Platform',
        description: 'Our customer data platform (CDP) services help you manage your customers, interactions, and loyalty programs.',
        image: 'cdm-banner copy.png',
        path: '/products/customer-data-platform',
    }
]

const services = [
    {
        id: 1,
        title: 'Consulting',
        description: 'Our team of experienced consultants can provide strategic guidance and insights to help you optimize your e-commerce operations and achieve your business goals.',
        image: 'banner-consulting.svg',
        path: '/services/consulting',
    },
    {
        id: 2,
        title: 'Commerce-as-a-Service',
        description: 'With our commerce-as-a-service offering, we handle all aspects of your e-commerce operations, from platform setup and maintenance to order fulfillment and customer support. Focus on growing your business while we take care of the rest.',
        image: 'commerce_automate.svg',
        path: '/services/commerce-as-a-service',
    },
    {
        id: 3,
        title: 'Custom Development',
        description: 'Our skilled developers can build custom solutions tailored to your specific business requirements. Whether you need a new e-commerce website, a mobile app, or integrations with third-party systems, we\'ve got you covered.',
        image: 'custom_development.svg',
        path: '/services/custom-development',
        objectPosition: '0 0',
    },
    {
        id: 4,
        title: 'Data Integrations',
        description: 'Seamlessly integrate your e-commerce platform with various data sources and systems, such as ERP, CRM, and marketing automation tools. We ensure smooth data flow and synchronization across your entire ecosystem.',
        image: 'data_integrations.svg',
        path: '/services/data-integration',
    },
    {
        id: 5,
        title: 'Website Analytics',
        description: 'Gain valuable insights into your website\'s performance and user behavior with our comprehensive analytics solutions. Make data-driven decisions to optimize your online presence and drive conversions.',
        image: 'website_analytics.jpg',
        path: '/services/website-analytics',
    },
];

const ServicesPage = () => {
    const navigate = useNavigate();
    const LPtheme = createTheme(getLPTheme('dark'));

    const handleCardClick = (path) => {
        navigate(`${path}`);
    };

    React.useEffect(() => {
        logPageView('Services Page');
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);

    return (
        <ThemeProvider theme={LPtheme}>
            <Helmet>
                <title>Services - Sinteli</title>
                <meta name="description" content="Explore our range of services, including consulting, commerce-as-a-service, custom development, data integrations, website analytics, and more." />
                <meta name="keywords" content="services, consulting, commerce-as-a-service, custom development, data integrations, website analytics" />
            </Helmet>

            <CssBaseline />
            <Container sx={{ py: 8 }}>
                <CommonAppBar mode={'dark'} />
                <StyledContainer sx={{ mt: 8, mb: 8, mx: 0, width: '100%' }}>
                    <Typography variant="h1" component="h1" gutterBottom>
                        Products
                    </Typography>
                    <Grid container spacing={4} sx={{ mt: 2, mb: 12 }}>
                        {products.map((product) => (
                            <Grid item xs={12} sm={6} md={4} key={product.id}>
                                <Card
                                    onClick={() => handleCardClick(product.path)}
                                    sx={{
                                        cursor: 'pointer',
                                        transition: 'box-shadow 0.3s ease-in-out', // Added transition for smooth hover effect
                                        '&:hover': {
                                            boxShadow: '0px 4px 12px rgba(211, 211, 211, 0.8)',
                                        },
                                        height: '100%',
                                    }}>
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={product.image}
                                        alt={product.title}
                                        sx={{
                                            objectPosition: product.objectPosition || '0 0',
                                        }}
                                    />
                                    <CardContent>
                                        <Typography variant="h5" component="div" gutterBottom>
                                            {product.title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {product.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    <Divider sx={{ mb: 8 }} />

                    <Typography variant="h2" component="h1" gutterBottom>
                        Enterprise
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Designed to help businesses succeed in the digital marketplace. From consulting and commerce-as-a-service to custom development and data integrations, we have the expertise to support your e-commerce needs.
                    </Typography>

                    <Grid container spacing={4} sx={{ mt: 4, mb: 12 }}>
                        {services.map((service) => (
                            <Grid item xs={12} sm={6} md={4} key={service.id}>
                                <Card
                                    onClick={() => handleCardClick(service.path)}
                                    sx={{
                                        cursor: 'pointer',
                                        transition: 'box-shadow 0.3s ease-in-out', // Added transition for smooth hover effect
                                        '&:hover': {
                                            boxShadow: '0px 4px 12px rgba(211, 211, 211, 0.8)',
                                        },
                                        height: '100%',
                                    }}>
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={service.image}
                                        alt={service.title}
                                        sx={{
                                            objectPosition: service.objectPosition || 'center',
                                        }}
                                    />
                                    <CardContent>
                                        <Typography variant="h5" component="div" gutterBottom>
                                            {service.title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {service.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>

                    <RequestServicesCard services={services.map(service => service.title)} />

                </StyledContainer>

                <Divider />
                <Footer />
            </Container>
        </ThemeProvider>
    );
};

export default ServicesPage;
