import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import { CircularProgress, Divider, styled } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import omni from '../assets/img/omni3.webp';
import omsBanner from '../assets/img/oms-banner.png';
import pimBanner from '../assets/img/pim-banner.png';
import cdmBanner from '../assets/img/cdm-banner.png';
import CommonAppBar from '../components/CommonAppBar';
import ecommerceBanner from '../assets/img/ecommerce-banner.png';
import article4Banner from '../assets/img/article-4-banner.png';
import omnichannelBanner from '../assets/img/article-2-banner.png';
import securityBanner from '../assets/img/article-5-banner.png';
import { logPageView } from '../firebaseApp';

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  padding: theme.spacing(8, 0),
}));

const BlogPage = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    logPageView('Blogs Page');
  }, []);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch('https://api.sinteli.com/api/blogs');
        const data = await response.json();
        setBlogs(data);
        console.log(data);
        setLoading(false);
      } catch (error) {
        // setError(error.message);
        setError(null);
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  const handleBlogClick = (blog) => {
    navigate(`/blog/${blog.headline}`, { state: { blog } });
  };

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography variant="h6" color="error">
          Sorry, something went wrong. Please try again later.
        </Typography>
      </Box>
    );
  }

  return (
    <Container sx={{ py: 8 }}>
      <Helmet>
        <title>Blog | Sinteli</title>
        <meta name="description" content="Explore insightful articles and blog posts about ecommerce management, industry trends, and best practices." />
        <meta name="keywords" content="ecommerce, management, blog, articles, trends, best practices" />
      </Helmet>
      <CommonAppBar mode={'dark'} />
      <StyledContainer maxWidth="md" sx={{ mt: 4, mb: 8 }}>
        <Typography variant="h1" component="h1" gutterBottom>
          Articles
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Link to="/blog/omnichannel-success" style={{ textDecoration: 'none' }}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={omni}
                  alt="The Future of Retail: Why Omnichannel Businesses Are Thriving"
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    June 14, 2024
                  </Typography>
                  <Typography variant="h2" component="h2" gutterBottom fontSize={22}>
                    The Future of Retail: Why Omnichannel Businesses Are Thriving
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={{ display: '-webkit-box', WebkitLineClamp: 4, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    In today's fast-paced and digitally-driven world, consumers expect seamless and consistent shopping experiences across all platforms. This expectation has given rise to the concept of omnichannel retailing, where businesses integrate their ...
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} md={6}>
            <Link to="/blog/centralized-oms-benefits" style={{ textDecoration: 'none' }}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={omsBanner}
                  alt="Streamlining Business Operations with a Centralized Order Management System"
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    June 5, 2024
                  </Typography>
                  <Typography variant="h2" component="h2" gutterBottom fontSize={22}>
                    Streamlining Business Operations with a Centralized Order Management System
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={{ display: '-webkit-box', WebkitLineClamp: 4, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    Discover how a centralized Order Management System (OMS) can revolutionize your business operations. Improve order accuracy, enhance inventory visibility, streamline fulfillment, and deliver exceptional customer experiences. Learn the benefits of implementing a centralized OMS and unlock the full potential of your business.
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} md={6}>
            <Link to="/blog/unlocking-pim-power" style={{ textDecoration: 'none' }}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={pimBanner}
                  alt="Unlocking the Power of Product Information Management (PIM)"
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    June 10, 2024
                  </Typography>
                  <Typography variant="h2" component="h2" gutterBottom fontSize={22}>
                    Unlocking the Power of Product Information Management (PIM)
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={{ display: '-webkit-box', WebkitLineClamp: 4, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    Discover how Product Information Management (PIM) can revolutionize your ecommerce operations. Centralize product data, improve data quality, enhance customer experience, and drive sales with a powerful PIM solution. Learn the benefits of implementing PIM and take your product information management to the next level.
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} md={6}>
            <Link to="/blog/harnessing-cdm-power" style={{ textDecoration: 'none' }}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={cdmBanner}
                  alt="Harnessing the Power of Customer Data Management (CDM)"
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    June 5, 2024
                  </Typography>
                  <Typography variant="h2" component="h2" gutterBottom fontSize={22}>
                    Harnessing the Power of Customer Data Management (CDM)
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={{ display: '-webkit-box', WebkitLineClamp: 4, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    Discover how Customer Data Management (CDM) can revolutionize your business operations. Collect, organize, and utilize customer data to deliver personalized experiences, gain valuable insights, enhance marketing effectiveness, and drive business growth. Learn the benefits of implementing a CDM solution and unlock the full potential of your customer data.
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} md={6}>
            <Link to="/blog/ecommerce-management-solution" style={{ textDecoration: 'none' }}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={ecommerceBanner}
                  alt="Revolutionizing Ecommerce Management: Introducing New All-in-One Solution"
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    June 1, 2024
                  </Typography>
                  <Typography variant="h2" component="h2" gutterBottom fontSize={22}>
                    Revolutionizing Ecommerce Management: Introducing New All-in-One Solution
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={{ display: '-webkit-box', WebkitLineClamp: 4, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    Discover our innovative ecommerce management solution that streamlines operations, enhances data accuracy, and provides valuable insights. Learn about the key features and benefits of our platform, including centralized order management, real-time product synchronization, advanced analytics, and seamless integration. Unlock the full potential of your online business with our cutting-edge solution.
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} md={6}>
            <Link to="/blog/ecommerce-challenges" style={{ textDecoration: 'none' }}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={article4Banner}
                  alt="5 Key Challenges in Ecommerce Management and How to Overcome Them"
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    April 15, 2024
                  </Typography>
                  <Typography variant="h2" component="h2" gutterBottom fontSize={22}>
                    5 Key Challenges in Ecommerce Management and How to Overcome Them
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={{ display: '-webkit-box', WebkitLineClamp: 4, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    Discover the five key challenges in ecommerce management and how our innovative solution can help you overcome them. From disjointed order management to inconsistent product data and inefficient customer handling, learn how to streamline your operations, drive growth, and achieve ecommerce success.
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>

          <Grid item xs={12} md={6}>
            <Link to="/blog/embracing-omnichannel" style={{ textDecoration: 'none' }}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={omnichannelBanner}
                  alt="The Future of Ecommerce: Embracing Omnichannel Strategies"
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    May 1, 2024
                  </Typography>
                  <Typography variant="h2" component="h2" gutterBottom fontSize={22}>
                    The Future of Ecommerce: Embracing Omnichannel Strategies
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={{ display: '-webkit-box', WebkitLineClamp: 4, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    Discover the importance of omnichannel strategies in the future of ecommerce. Learn how Sinteli can help businesses create a seamless and integrated shopping experience across multiple channels, driving sales growth and customer loyalty. Explore case studies of successful omnichannel implementations and unlock the full potential of your business.
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} md={6}>
            <Link to="/blog/data-security-in-ecommerce" style={{ textDecoration: 'none' }}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={securityBanner}
                  alt="The Importance of Data Security in Ecommerce and How We Ensure It"
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    March 10, 2025
                  </Typography>
                  <Typography variant="h2" component="h2" gutterBottom fontSize={22}>
                    The Importance of Data Security in Ecommerce and How We Ensure It
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={{ display: '-webkit-box', WebkitLineClamp: 4, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    Discover the critical role of data security in ecommerce and how Sinteli ensures the safety and privacy of customer information. Learn about our robust security measures, compliance with industry standards, and commitment to building trust with our customers. Protect your data and grow your business with confidence using our secure ecommerce platform.
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
          {blogs.map((blog) => (
            <Grid item xs={12} md={6} key={blog.headline}>
              <Card onClick={() => handleBlogClick(blog)} style={{ cursor: 'pointer' }}>
                <CardMedia
                  component="img"
                  height="140"
                  image={`${process.env.PUBLIC_URL}/${blog.image}`}
                  alt={blog.headline}
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    {new Date(blog.date_published * 1000).toLocaleDateString()}
                  </Typography>
                  <Typography variant="h2" component="h2" gutterBottom fontSize={22}>
                    {blog.headline}
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={{ display: '-webkit-box', WebkitLineClamp: 4, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {blog.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        {
          loading && (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
              <CircularProgress />
            </Box>
          )
        }
      </StyledContainer>

      <Box sx={{ bgcolor: 'background.default' }}>
        <Divider />
        <Footer />
      </Box>

      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": "The Future of Retail: Why Omnichannel Businesses Are Thriving",
            "image": "${omni}",
            "author": {
              "@type": "Person",
              "name": "Sinteli Team"
            },
            "datePublished": "2024-06-14",
            "description": "In today's fast-paced and digitally-driven world, consumers expect seamless and consistent shopping experiences across all platforms. This expectation has given rise to the concept of omnichannel retailing, where businesses integrate their ..."
          }
        `}
      </script>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": "Streamlining Business Operations with a Centralized Order Management System",
            "image": "${omsBanner}",
            "author": {
              "@type": "Person",
              "name": "Sinteli Team"
            },
            "datePublished": "2024-07-05",
            "description": "Discover how a centralized Order Management System (OMS) can revolutionize your business operations. Improve order accuracy, enhance inventory visibility, streamline fulfillment, and deliver exceptional customer experiences. Learn the benefits of implementing a centralized OMS and unlock the full potential of your business."
          }
        `}
      </script>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": "Unlocking the Power of Product Information Management (PIM)",
            "image": "${pimBanner}",
            "author": {
              "@type": "Person",
              "name": "Sinteli Team"
            },
            "datePublished": "2024-08-10",
            "description": "Discover how Product Information Management (PIM) can revolutionize your ecommerce operations. Centralize product data, improve data quality, enhance customer experience, and drive sales with a powerful PIM solution. Learn the benefits of implementing PIM and take your product information management to the next level."
          }
        `}
      </script>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": "Harnessing the Power of Customer Data Management (CDM)",
            "image": "${cdmBanner}",
            "author": {
              "@type": "Person",
              "name": "Sinteli Team"
            },
            "datePublished": "2024-09-05",
            "description": "Discover how Customer Data Management (CDM) can revolutionize your business operations. Collect, organize, and utilize customer data to deliver personalized experiences, gain valuable insights, enhance marketing effectiveness, and drive business growth. Learn the benefits of implementing a CDM solution and unlock the full potential of your customer data."
          }
        `}
      </script>
      <script type="application/ld+json">
        {`
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "headline": "Revolutionizing Ecommerce Management: Introducing New All-in-One Solution",
      "image": "${ecommerceBanner}",
      "author": {
        "@type": "Person",
        "name": "Sinteli Team"
      },
      "datePublished": "2024-10-01",
      "description": "Discover our innovative ecommerce management solution that streamlines operations, enhances data accuracy, and provides valuable insights. Learn about the key features and benefits of our platform, including centralized order management, real-time product synchronization, advanced analytics, and seamless integration. Unlock the full potential of your online business with our cutting-edge solution."
    }
  `}
      </script>

      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": "5 Key Challenges in Ecommerce Management and How to Overcome Them",
            "image": "${article4Banner}",
            "author": {
              "@type": "Person",
              "name": "Sinteli Team"
            },
            "datePublished": "2024-04-15",
            "description": "Discover the five key challenges in ecommerce management and how our innovative solution can help you overcome them. From disjointed order management to inconsistent product data and inefficient customer handling, learn how to streamline your operations, drive growth, and achieve ecommerce success."
          }
        `}
      </script>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": "The Future of Ecommerce: Embracing Omnichannel Strategies",
            "image": "${omnichannelBanner}",
            "author": {
              "@type": "Person",
              "name": "Sinteli Team"
            },
            "datePublished": "2024-12-01",
            "description": "Discover the importance of omnichannel strategies in the future of ecommerce. Learn how Sinteli can help businesses create a seamless and integrated shopping experience across multiple channels, driving sales growth and customer loyalty. Explore case studies of successful omnichannel implementations and unlock the full potential of your business."
          }
        `}
      </script>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": "The Importance of Data Security in Ecommerce and How We Ensure It",
            "image": "${securityBanner}",
            "author": {
              "@type": "Person",
              "name": "Sinteli Team"
            },
            "datePublished": "2025-01-10",
            "description": "Discover the critical role of data security in ecommerce and how Sinteli ensures the safety and privacy of customer information. Learn about our robust security measures, compliance with industry standards, and commitment to building trust with our customers. Protect your data and grow your business with confidence using our secure ecommerce platform."
          }
        `}
      </script>

    </Container>
  );
};

export default BlogPage;
