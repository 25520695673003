import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ToggleColorMode from './ToggleColorMode';
import { Link as RouterLink } from 'react-router-dom';

import Sitemark from './SitemarkIcon';

function CommonAppBar({ mode, toggleColorMode }) {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    return (
        <AppBar
            position="fixed"
            sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', mt: 2 }}
        >
            <Container maxWidth="lg">
                <Toolbar
                    variant="regular"
                    sx={(theme) => ({
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexShrink: 0,
                        borderRadius: '999px',
                        backdropFilter: 'blur(24px)',
                        maxHeight: 40,
                        // border: '1px solid',
                        // borderColor: 'divider',
                        // bgcolor: 'hsla(220, 60%, 99%, 0.6)',
                        // boxShadow:
                        //     '0 1px 2px hsla(210, 0%, 0%, 0.05), 0 2px 12px hsla(210, 100%, 80%, 0.5)',
                        // ...theme.applyStyles('dark', {
                        //     bgcolor: 'hsla(220, 0%, 0%, 0.7)',
                        //     boxShadow:
                        //         '0 1px 2px hsla(210, 0%, 0%, 0.5), 0 2px 12px hsla(210, 100%, 25%, 0.3)',
                        // }),
                    })}
                >
                    <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
                        <Sitemark />
                        {/* <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                            <Button
                                variant="text"
                                color="info"
                                size="small"
                                href="/about"
                            >
                                Company
                            </Button>
                            <Button
                                variant="text"
                                color="info"
                                size="small"
                                href="/blog"
                            >
                                Blog
                            </Button>
                            <Button
                                variant="text"
                                color="info"
                                size="small"
                                href="/services"
                            >
                                Products
                            </Button>
                            <Button
                                variant="text"
                                color="info"
                                size="small"
                                href="/contact"
                            >
                                Contact
                            </Button>
                            <Button
                                variant="text"
                                color="info"
                                size="small"
                                href="/early-access"
                            >
                                Early Access
                            </Button>
                        </Box> */}
                    </Box>
                    <Box
                        sx={{
                            display: { xs: 'none', md: 'flex' },
                            gap: 0.5,
                            alignItems: 'center',
                        }}
                    >
                        {/* <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} /> */}
                        {/* <Button color="primary" variant="text" size="small" href="https://admin.sinteli.com">
                            Sign in
                        </Button>
                        <Button color="primary" variant="contained" size="small" href="/signup">
                            Sign up
                        </Button> */}
                        <RouterLink to="/" style={{ textDecoration: 'none' }}>
                            <Button
                                sx={{
                                    '&:hover': {
                                        background: 'linear-gradient(45deg, #B22CB3, #c1fe72, #50425F)',
                                        backgroundSize: '200% 200%',
                                        animation: 'gradient 3s ease infinite',
                                        color: 'black',
                                    },
                                    '@keyframes gradient': {
                                        '0%': {
                                            backgroundPosition: '0% 50%',
                                        },
                                        '50%': {
                                            backgroundPosition: '100% 50%',
                                        },
                                        '100%': {
                                            backgroundPosition: '0% 50%',
                                        },
                                    },
                                    color: 'white',
                                    fontWeight: 'bold',
                                }}
                            >Back to Home Page</Button>
                        </RouterLink>
                    </Box>
                   
                </Toolbar>
            </Container>
        </AppBar>
    );
}

CommonAppBar.propTypes = {
    mode: PropTypes.oneOf(['dark', 'light']).isRequired,
    toggleColorMode: PropTypes.func.isRequired,
};

export default CommonAppBar;
