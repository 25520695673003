import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Divider, styled } from '@mui/material';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import CommonAppBar from '../components/CommonAppBar';
import { logButtonClick, logPageView } from '../firebaseApp';

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  padding: theme.spacing(8, 0),
}));

const FAQsPage = () => {
  React.useEffect(() => {
    logPageView('FAQs Page');
  }, []);

  const handleChange = (panelContent) => (event, isExpanded) => {
    if (isExpanded) {
      logButtonClick('Button Click: FAQ: ', panelContent);
    }
  };

  return (
    <Container sx={{ py: 8 }}>
      <CommonAppBar mode={'dark'} />
      <StyledContainer maxWidth="md" sx={{ mt: 4, mb: 8 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Frequently Asked Questions
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Accordion
            onChange={handleChange('1. What is the onboarding process like for new users?')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle2">
                1. What is the onboarding process like for new users?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                Our onboarding process is designed to be seamless and efficient. Once you sign up, you'll receive a detailed guide to help you get started. Our support team is available to assist you with setup, and Professional and Enterprise Plan customers receive dedicated onboarding assistance to ensure a smooth transition.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            onChange={handleChange('2. How secure is my data on your platform?')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle2">
                2. How secure is my data on your platform?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                Data security is our top priority. Our platform uses state-of-the-art encryption and security protocols to protect your data. We comply with industry standards and regularly conduct security audits to ensure your information is safe and secure.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            onChange={handleChange('3. Can I integrate your platform with my existing systems?')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle2">
                3. Can I integrate your platform with my existing systems?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                Yes, our platform is designed to integrate seamlessly with major ecommerce systems like BigCommerce, SAP, Salesforce, and more. This ensures that you can continue using your existing tools while benefiting from the centralized management our platform provides.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            onChange={handleChange('4. What kind of customer support can I expect?')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle2">
                4. What kind of customer support can I expect?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                We offer comprehensive customer support to ensure you get the most out of our platform. Starter Plan users have access to email support, while Professional Plan users receive priority email support. Enterprise Plan users benefit from 24/7 phone and email support, along with a dedicated account manager for personalized assistance.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            onChange={handleChange('5. How does your pricing work?')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle2">
                5. How does your pricing work?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                We offer flexible pricing plans to suit the needs of businesses of all sizes. Our Starter Plan is designed for small businesses and includes basic features at an affordable price. The Professional Plan offers advanced features and priority support for growing businesses. For enterprises with complex requirements, our Enterprise Plan provides custom solutions and dedicated support. You can find more details on our pricing page.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            onChange={handleChange('6. Do you offer a free trial?')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle2">
                6. Do you offer a free trial?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                Yes, we offer a 14-day free trial for our Starter and Professional Plans. During the trial period, you'll have access to all the features included in the respective plan. This allows you to explore our platform and determine if it meets your business needs before making a commitment.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            onChange={handleChange('7. How can I get started with your platform?')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle2">
                7. How can I get started with your platform?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2">
                Getting started with our platform is easy. Simply sign up for an account on our website and choose the plan that best suits your needs. Once you've completed the registration process, you'll have access to our platform and can start exploring its features. Our onboarding guides and support team are available to help you navigate the platform and set up your ecommerce management system.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </StyledContainer>

      <StyledContainer maxWidth="md" sx={{ mt: 4, mb: 8, textAlign: 'center' }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Have more questions?
        </Typography>
        <Typography variant="body1" paragraph>
          If you couldn't find the answer to your question or have any other inquiries, please don't hesitate to reach out to our support team.
        </Typography>
        <Button
          component={Link}
          to="/contact"
          variant="contained"
          color="primary"
          size="large"
          sx={{ mt: 2 }}
        >
          Contact Us
        </Button>
      </StyledContainer>

      <Box sx={{ bgcolor: 'background.default' }}>
        <Divider />
        <Footer />
      </Box>
    </Container>
  );
};

export default FAQsPage;
