import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { Card, CardContent, Divider, List, ListItem } from '@mui/material';
import Footer from '../../components/Footer';
import getLPTheme from '../../getLPTheme';
import cdmBanner from '../../assets/img/cdm-banner.png';
import { Helmet } from 'react-helmet';
import CommonAppBar from '../../components/CommonAppBar';
import { logPageView } from '../../firebaseApp';
import CommerceServiceCTA from '../../components/CommerceServiceCTA';

const StyledContainer = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(8, 0),
}));

const BannerContainer = styled('div')(({ theme }) => ({
    backgroundImage: `url(${cdmBanner})`,
    borderRadius: theme.shape.borderRadius,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '400px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
    ...theme.applyStyles('dark', {
        boxShadow: '0 0 24px 12px hsla(267, 100%, 35%, 0.2)',
        outlineColor: 'hsla(210, 100%, 80%, 0.1)',
    }),
}));

const CDMArticle = () => {
    const LPtheme = createTheme(getLPTheme('dark'));

    React.useEffect(() => {
        logPageView('CDMArticle Page');
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);

    const articleContent = (
        <>
            <BannerContainer alt="Customer Data Management" />

            <Typography variant="body2" paragraph>
                June 5, 2024
            </Typography>
            <Typography
                variant="h1"
                component="h1"
                gutterBottom
                sx={{ color: 'white' }}
            >
                Harnessing the Power of Customer Data Management (CDM)
            </Typography>

            <Divider
                sx={{
                    backgroundColor: 'white',
                    width: '100%',
                    height: 1,
                    marginBottom: 2,
                }}
            />

            <Typography variant="body1" paragraph fontSize={18} my={5}>
                In the era of data-driven business, effectively managing customer data is crucial for success. Customer Data Management (CDM) is a strategic approach that enables businesses to collect, organize, and utilize customer data to gain valuable insights and drive personalized experiences. Let's explore the key benefits of implementing a CDM solution.
            </Typography>

            <Card sx={{ mb: 4, px: 2 }}>
                <CardContent>
                    <Typography variant="h5" component="h2" gutterBottom mt={2}>
                        What is Customer Data Management (CDM)?
                    </Typography>
                    <Typography variant="body1" paragraph mt={2}>
                        Customer Data Management (CDM) is the process of collecting, organizing, and managing customer data from various sources to create a unified and comprehensive view of each customer. It involves integrating data from multiple touchpoints, such as website interactions, purchase history, social media, and customer support, to build a holistic customer profile.
                    </Typography>
                </CardContent>
            </Card>

            <Typography variant="h2" component="h2" gutterBottom mt={10}>
                Benefits of Customer Data Management
            </Typography>

            <Divider />
            <List>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        <strong>Personalized Customer Experiences:</strong> CDM enables businesses to deliver personalized experiences by leveraging customer data. By understanding customer preferences, behavior, and history, businesses can tailor their offerings, recommendations, and communications to individual customers, leading to increased engagement and loyalty.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        <strong>Improved Customer Insights:</strong> With CDM, businesses can gain valuable insights into customer behavior, preferences, and trends. By analyzing customer data, businesses can identify patterns, segment customers, and make data-driven decisions to optimize their strategies and improve customer satisfaction.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        <strong>Enhanced Marketing Effectiveness:</strong> CDM empowers businesses to create targeted marketing campaigns based on customer data. By segmenting customers and understanding their preferences, businesses can deliver relevant and timely marketing messages, increasing the effectiveness of their marketing efforts and driving higher conversion rates.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        <strong>Improved Customer Retention:</strong> CDM helps businesses identify at-risk customers and proactively address their needs. By analyzing customer data, businesses can detect signs of churn and take timely actions to retain customers, such as offering personalized incentives or providing exceptional customer support.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        <strong>Data-Driven Decision Making:</strong> CDM provides businesses with a wealth of customer data that can be used to make informed decisions. By leveraging customer insights, businesses can optimize their products, services, and strategies to better meet customer needs and preferences, ultimately driving business growth and profitability.
                    </Typography>
                </ListItem>
            </List>

            <Typography variant="h2" component="h2" gutterBottom mt={10}>
                Case Studies
            </Typography>

            <Divider />
            <Card sx={{ mb: 4, px: 2, mt: 4 }}>
                <CardContent>
                    <Typography variant="h5" component="h3" gutterBottom mt={2}>
                        Case Study 1: Starbucks
                    </Typography>
                    <Typography variant="body1" paragraph fontSize={18}>
                        Starbucks, a global coffee chain, leverages CDM to deliver personalized experiences to its customers. Through its mobile app and loyalty program, Starbucks collects customer data such as purchase history, preferences, and location. By analyzing this data, Starbucks offers personalized recommendations, rewards, and promotions to individual customers, enhancing customer engagement and loyalty.
                    </Typography>
                </CardContent>
            </Card>

            <Card sx={{ mb: 4, px: 2, mt: 4 }}>
                <CardContent>
                    <Typography variant="h5" component="h3" gutterBottom mt={2}>
                        Case Study 2: Netflix
                    </Typography>
                    <Typography variant="body1" paragraph fontSize={18}>
                        Netflix, a leading streaming service, utilizes CDM to provide personalized content recommendations to its users. By collecting and analyzing user data, such as viewing history, ratings, and preferences, Netflix's recommendation engine suggests relevant movies and TV shows to each user. This personalized approach has been a key factor in Netflix's success, driving user engagement and retention.
                    </Typography>
                </CardContent>
            </Card>

            <Typography variant="h2" component="h2" gutterBottom mt={10}>
                Implementing a Customer Data Management Solution
            </Typography>

            <Divider />
            <Typography variant="body1" paragraph fontSize={18} mt={2}>
                Implementing a CDM solution requires careful planning and execution. Businesses should assess their current customer data landscape, identify data sources, and define their CDM strategy. Partnering with a reputable CDM provider can help ensure a smooth implementation and seamless integration with existing systems.
            </Typography>

            <Typography variant="h2" component="h2" gutterBottom mt={10}>
                Conclusion
            </Typography>

            <Typography variant="body1" paragraph fontSize={18}>
                Customer Data Management (CDM) is a powerful tool for businesses looking to harness the potential of customer data. By collecting, organizing, and utilizing customer data effectively, businesses can deliver personalized experiences, gain valuable insights, enhance marketing effectiveness, improve customer retention, and make data-driven decisions. CDM is essential for businesses aiming to stay competitive in today's customer-centric market.
            </Typography>
            <Typography variant="body1" paragraph fontSize={18}>
                Embrace the power of CDM and unlock the full potential of your customer data. Implement a robust CDM solution, transform your customer relationships, and drive business growth with data-driven strategies.
            </Typography>
        </>
    );

    return (
        <ThemeProvider theme={LPtheme}>
            <Helmet>
                <title>Harnessing the Power of Customer Data Management (CDM) | Sinteli Blog</title>
                <meta name="description" content="Discover how Customer Data Management (CDM) can revolutionize your business operations. Collect, organize, and utilize customer data to deliver personalized experiences, gain valuable insights, enhance marketing effectiveness, and drive business growth. Learn the benefits of implementing a CDM solution and unlock the full potential of your customer data." />
                <meta name="keywords" content="customer data management, CDM, customer data, personalization, customer insights, marketing effectiveness, customer retention, data-driven decisions" />
            </Helmet>

            <CssBaseline />
            <CommonAppBar mode={'dark'} />
            <StyledContainer maxWidth="md" sx={{ mt: 10, px: 3 }}>
                {articleContent}
            </StyledContainer>
            <Box sx={{ bgcolor: 'background.default' }}>
                <CommerceServiceCTA />
                <Divider />
                <Footer />
            </Box>
            <script type="application/ld+json">
                {`
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "headline": "Harnessing the Power of Customer Data Management (CDM)",
      "image": "${cdmBanner}",
      "author": {
        "@type": "Person",
        "name": "Sinteli Team"
      },
      "datePublished": "2024-09-05",
      "dateModified": "2024-09-05",
      "publisher": {
        "@type": "Organization",
        "name": "Sinteli",
        "logo": {
          "@type": "ImageObject",
          "url": "https://sinteli.com/logo.png"
        }
      },
      "description": "Discover how Customer Data Management (CDM) can revolutionize your business operations. Collect, organize, and utilize customer data to deliver personalized experiences, gain valuable insights, enhance marketing effectiveness, and drive business growth. Learn the benefits of implementing a CDM solution and unlock the full potential of your customer data."
    }
  `}
            </script>
        </ThemeProvider>
    );
};

export default CDMArticle;
