import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { Card, CardContent, Divider, List, ListItem } from '@mui/material';
import CTA from '../../components/CTA';
import Footer from '../../components/Footer';
import getLPTheme from '../../getLPTheme';
import omnichannelBanner from '../../assets/img/article-2-banner.png';
import { Helmet } from 'react-helmet';
import CommonAppBar from '../../components/CommonAppBar';
import { logPageView } from '../../firebaseApp';

const StyledContainer = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(8, 0),
}));

const BannerContainer = styled('div')(({ theme }) => ({
    backgroundImage: `url(${omnichannelBanner})`,
    borderRadius: theme.shape.borderRadius,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '400px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
    ...theme.applyStyles('dark', {
        boxShadow: '0 0 24px 12px hsla(267, 100%, 35%, 0.2)',
        outlineColor: 'hsla(210, 100%, 80%, 0.1)',
    }),
}));

const EmbracingOmnichannelArticle = () => {
    const LPtheme = createTheme(getLPTheme('dark'));

    React.useEffect(() => {
        logPageView('EmbracingOmnichannelArticle Page');
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);

    const articleContent = (
        <>
            <BannerContainer alt="Omnichannel Strategies" />

            <Typography variant="body2" paragraph>
                May 1, 2024
            </Typography>
            <Typography
                variant="h1"
                component="h1"
                gutterBottom
                sx={{ color: 'white' }}
            >
                The Future of Ecommerce: Embracing Omnichannel Strategies
            </Typography>

            <Divider
                sx={{
                    backgroundColor: 'white',
                    width: '100%',
                    height: 1,
                    marginBottom: 2,
                }}
            />

            <Typography variant="body1" paragraph fontSize={18} my={5}>
                In today's rapidly evolving ecommerce landscape, businesses that embrace omnichannel strategies are poised for success. Omnichannel retailing involves creating a seamless and integrated shopping experience across multiple channels, including online stores, mobile apps, social media, and physical retail locations. By adopting an omnichannel approach, businesses can enhance customer engagement, increase sales, and build long-term customer loyalty.
            </Typography>

            <Typography variant="h2" component="h2" gutterBottom mt={10}>
                The Benefits of Omnichannel Retailing
            </Typography>

            <Divider />
            <List>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        <strong>Improved Customer Experience:</strong> Omnichannel strategies allow customers to interact with a brand through their preferred channels, ensuring a consistent and personalized experience across all touchpoints. This leads to higher customer satisfaction and loyalty.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        <strong>Increased Sales and Revenue:</strong> By providing multiple channels for customers to engage and make purchases, businesses can capture a larger market share and drive sales growth. Omnichannel shoppers tend to have a higher lifetime value compared to single-channel shoppers.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        <strong>Better Data Collection and Analysis:</strong> Omnichannel strategies enable businesses to collect valuable customer data across various channels, providing insights into customer behavior, preferences, and purchasing patterns. This data can be used to optimize marketing efforts, personalize experiences, and make data-driven business decisions.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        <strong>Competitive Advantage:</strong> Businesses that successfully implement omnichannel strategies gain a competitive edge in the market. They can differentiate themselves by offering a superior customer experience and leveraging data to stay ahead of customer needs and preferences.
                    </Typography>
                </ListItem>
            </List>

            <Typography variant="h2" component="h2" gutterBottom mt={10}>
                Seamless Integration 
            </Typography>

            <Divider />
            <Typography variant="body1" paragraph fontSize={18} mt={2}>
                Sinteli is designed to facilitate seamless integration across multiple channels, enabling businesses to implement effective omnichannel strategies. Our platform provides a centralized hub for managing product information, inventory, orders, and customer data, ensuring consistency and accuracy across all channels.
            </Typography>
            <Typography variant="body1" paragraph fontSize={18}>
                With our product, businesses can:
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        - Synchronize product information and inventory levels in real-time across online and offline channels.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        - Manage orders from multiple channels through a single interface, streamlining fulfillment processes.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        - Gain valuable insights into customer behavior and preferences through advanced analytics and reporting.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        - Personalize customer experiences based on data collected from various touchpoints.
                    </Typography>
                </ListItem>
            </List>

            <Typography variant="h2" component="h2" gutterBottom mt={10}>
                Case Studies of Successful Omnichannel Implementations
            </Typography>

            <Divider />
            <Card sx={{ mb: 4, px: 2, mt: 4 }}>
                <CardContent>
                    <Typography variant="h5" component="h3" gutterBottom mt={2}>
                        Case Study 1: Sephora
                    </Typography>
                    <Typography variant="body1" paragraph fontSize={18}>
                        Sephora, a leading beauty retailer, has successfully implemented an omnichannel strategy. They offer a seamless experience across their website, mobile app, and physical stores. Customers can browse and purchase products online, access personalized recommendations, and easily pick up their orders in-store. Sephora's omnichannel approach has resulted in a 30% increase in online sales and a 20% increase in in-store sales.
                    </Typography>
                </CardContent>
            </Card>

            <Card sx={{ mb: 4, px: 2, mt: 4 }}>
                <CardContent>
                    <Typography variant="h5" component="h3" gutterBottom mt={2}>
                        Case Study 2: Starbucks
                    </Typography>
                    <Typography variant="body1" paragraph fontSize={18}>
                        Starbucks has successfully leveraged omnichannel strategies to enhance customer engagement and loyalty. Their mobile app allows customers to order and pay ahead, earn rewards, and access personalized offers. Starbucks also integrates their app with their in-store experience, enabling customers to seamlessly redeem rewards and collect points. As a result, Starbucks has seen a 26% increase in loyalty program membership and a 12% increase in average ticket size.
                    </Typography>
                </CardContent>
            </Card>

            <Typography variant="h2" component="h2" gutterBottom mt={10}>
                Conclusion
            </Typography>

            <Typography variant="body1" paragraph fontSize={18}>
                Embracing omnichannel strategies is no longer optional for businesses that want to thrive in the future of ecommerce. By creating a seamless and integrated shopping experience across multiple channels, businesses can enhance customer engagement, drive sales growth, and gain a competitive advantage in the market.
            </Typography>
            <Typography variant="body1" paragraph fontSize={18}>
                Sinteli is designed to empower businesses to implement effective omnichannel strategies with ease. With our platform, businesses can streamline operations, ensure data consistency, and deliver personalized experiences to customers across all touchpoints.
            </Typography>
            <Typography variant="body1" paragraph fontSize={18}>
                Don't get left behind in the rapidly evolving ecommerce landscape. Embrace omnichannel strategies and unlock the full potential of your business with our innovative product. Contact us today to learn more and start your omnichannel journey.
            </Typography>
        </>
    );

    return (
        <ThemeProvider theme={LPtheme}>
            <Helmet>
                <title>The Future of Ecommerce: Embracing Omnichannel Strategies | Sinteli Blog</title>
                <meta name="description" content="Discover the importance of omnichannel strategies in the future of ecommerce. Learn how Sinteli can help businesses create a seamless and integrated shopping experience across multiple channels, driving sales growth and customer loyalty. Explore case studies of successful omnichannel implementations and unlock the full potential of your business." />
                <meta name="keywords" content="omnichannel strategies, ecommerce, customer experience, sales growth, data integration, personalization, case studies" />
            </Helmet>

            <CssBaseline />
            <CommonAppBar mode={'dark'} />
            <StyledContainer maxWidth="md" sx={{ mt: 10, px: 3 }}>
                {articleContent}
            </StyledContainer>
            <Box sx={{ bgcolor: 'background.default' }}>
                <CTA />
                <Divider />
                <Footer />
            </Box>
            <script type="application/ld+json">
                {`
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "headline": "The Future of Ecommerce: Embracing Omnichannel Strategies",
      "image": "${omnichannelBanner}",
      "author": {
        "@type": "Person",
        "name": "Sinteli Team"
      },
      "datePublished": "2024-12-01",
      "dateModified": "2024-12-01",
      "publisher": {
        "@type": "Organization",
        "name": "Sinteli",
        "logo": {
          "@type": "ImageObject",
          "url": "https://sinteli.com/logo.png"
        }
      },
      "description": "Discover the importance of omnichannel strategies in the future of ecommerce. Learn Sinteli can help businesses create a seamless and integrated shopping experience across multiple channels, driving sales growth and customer loyalty. Explore case studies of successful omnichannel implementations and unlock the full potential of your business."
    }
  `}
            </script>
        </ThemeProvider>
    );
};

export default EmbracingOmnichannelArticle;
