import * as React from 'react';
import { Typography, Container, Box, Divider, Grid, styled, CssBaseline, Stack, Card, CardMedia, CardContent } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import { logPageView } from '../../firebaseApp';
import getLPTheme from '../../getLPTheme';
import CommonAppBar from '../../components/CommonAppBar';
import Footer from '../../components/Footer';
import RequestServicesCard from './components/RequestServicesCard';
import BlogSnippets from './components/BlogSnippets';
import TextImageSection from './components/TextImageSection';

const StyledContainer = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(8, 0),
}));

const features = [
    "End-to-end e-commerce platform setup and maintenance",
    "Product catalog management and optimization",
    "Order processing and fulfillment",
    "Payment gateway integration and secure transactions",
    "Customer support and issue resolution",
    "Scalable infrastructure and performance optimization",
    "Continuous updates and feature enhancements",
];

const CommerceAsAServicePage = () => {
    const LPtheme = createTheme(getLPTheme('dark'));

    React.useEffect(() => {
        logPageView('Commerce as a Service Page');
        window.scrollTo(0, 0);
    }, []);

    return (
        <ThemeProvider theme={LPtheme}>
            <Helmet>
                <title>Commerce as a Service - Sinteli</title>
                <meta name="description" content="Focus on growing your business while we handle all aspects of your e-commerce operations. From platform setup and maintenance to order fulfillment and customer support, our commerce-as-a-service offering has you covered." />
                <meta name="keywords" content="commerce as a service, e-commerce, platform, fulfillment, customer support" />
            </Helmet>

            <CommonAppBar mode={'dark'} />

            <CssBaseline />
            <Container sx={{ pb: 8 }}>
                <CommonAppBar mode={'dark'} />
                <StyledContainer sx={{ mb: 8, mx: 0, width: '100%' }}>
                    <CardMedia
                        component="img"
                        image="/commerce_automate.svg"
                        alt="Commerce as a Service"
                        sx={{
                            objectFit: 'cover',
                            width: '100%',
                            objectPosition: '0 0',
                            height: '600px',
                            mb: 4,
                        }}
                    />

                    <Typography variant="h2" component="h1" gutterBottom>
                        Commerce as a Service
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Our commerce-as-a-service offering takes the hassle out of running an online store. We handle all aspects of your e-commerce operations, allowing you to focus on growing your business. From platform setup and maintenance to order fulfillment and customer support, we've got you covered.
                    </Typography>

                    <Box sx={{ mt: 8, mb: 8 }}>
                        <Grid container spacing={2.5}>
                            {features.map((feature, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Stack
                                        direction="column"
                                        component={Card}
                                        spacing={1}
                                        useFlexGap
                                        sx={{
                                            color: 'inherit',
                                            px: 3,
                                            py: 1,
                                            height: '100%',
                                            border: '1px solid',
                                            borderColor: 'hsla(220, 25%, 25%, .3)',
                                            background: 'transparent',
                                            backgroundColor: 'grey.900',
                                            boxShadow: 'none',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            alignContent: 'center',
                                        }}
                                    >
                                        <div>
                                            <Typography gutterBottom sx={{ fontWeight: 'medium' }} variant='h6'>
                                                {feature}
                                            </Typography>
                                        </div>
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    <Divider sx={{ mt: 8, mb: 12 }} />

                    <TextImageSection
                        title='Seamless E-Commerce Experience'
                        description="Our commerce-as-a-service solution provides a seamless e-commerce experience for your customers. We ensure that your online store is always up and running, with fast loading times and smooth navigation. Our team takes care of all the technical aspects, so you can concentrate on delivering exceptional products and services."
                        image="/article-2-banner copy.png"
                        textOnLeft={false}
                    />

                    <TextImageSection
                        title='Scalable and Reliable Infrastructure'
                        description="We understand that your business needs may change over time. That's why our commerce-as-a-service offering is built on a scalable and reliable infrastructure. Whether you're just starting out or experiencing rapid growth, our platform can handle the increased traffic and transactions. We ensure that your online store remains fast, secure, and available to your customers at all times."
                        image="/data_integrations.svg"
                    />

                    <Grid container spacing={6} sx={{ py: 6 }}>
                        <Grid item xs={12} sm={6} md={6} key={0}>
                            <Card sx={{ height: '100%' }}>
                                <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        Continuous Updates and Enhancements
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        We stay up-to-date with the latest e-commerce trends and technologies to provide you with a competitive edge. Our team continuously works on updates and enhancements to improve the functionality, performance, and user experience of your online store. You can rest assured that your e-commerce platform will always be at the forefront of innovation.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} key={0}>
                            <Card sx={{ height: '100%' }}>
                                <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        Expert Support and Guidance
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Our team of e-commerce experts is always ready to provide you with the support and guidance you need. From initial setup to ongoing maintenance and optimization, we're here to help you every step of the way. We offer personalized support to ensure that your online store is running smoothly and efficiently, maximizing your sales and customer satisfaction.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <BlogSnippets title={'Related Blog Posts'} />
                    <RequestServicesCard services={features} />

                </StyledContainer>

                <Divider />
                <Footer />
            </Container>
        </ThemeProvider>
    );
};

export default CommerceAsAServicePage;
