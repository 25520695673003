import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { logButtonClick, logComponentHidden, logComponentVisible } from '../firebaseApp';


const userTestimonials = [
    {
        avatar: <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />,
        name: 'Automated Reports',
        testimonial:
            'Create client-ready reports in minutes with in-depth data visualization and insights.',
    },
    {
        avatar: <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />,
        name: 'Easy-to-use Dashboard',
        testimonial:
            "Go beyond the basic metrics with AI that predicts trends and optimizes strategies.",
    },
    {
        avatar: <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />,
        name: 'No-code Integrations',
        testimonial:
            'Connect all your tools in one customizable dashboard, deesigned to fit your workflow.',
    },
];

export default function DetailedFeatures() {
    const componentRef = React.useRef(null);
    const visibleTimestampRef = React.useRef(null);

    React.useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    visibleTimestampRef.current = new Date().getTime();
                    logComponentVisible('DetailedFeatures Component');
                } else {
                    const hiddenTimestamp = new Date().getTime();
                    const durationMs = hiddenTimestamp - visibleTimestampRef.current;
                    logComponentHidden('DetailedFeatures Component', durationMs);
                }
            });
        });

        const currentRef = componentRef.current; // Copying componentRef.current to a variable

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    const handleCardClick = (cardId) => {
        // Add your card click logic here
        // window.location.href = '/blog/ecommerce-management-solution';
        logButtonClick('Button Click: DetailedFeatures: ' + cardId);
    };

    return (
        <Container
            id="testimonials"
            sx={{
                backgroundColor: '#000d24',
                pb: { xs: 8, sm: 16 },
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 3, sm: 6 },
            }}
        >
            <Grid container spacing={2}>
                {userTestimonials.map((testimonial, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
                        <Card
                            onClick={() => handleCardClick(testimonial.name)}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                flexGrow: 1,
                                p: 1,
                                position: 'relative',
                                cursor: 'pointer',
                                transition: 'box-shadow 0.3s ease-in-out', // Added transition for smooth hover effect
                                '&:hover': {
                                    boxShadow: '0px 4px 12px rgba(211, 211, 211, 0.8)',
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    pr: 2,
                                }}
                            >
                                <CardHeader title={testimonial.name} />
                            </Box>
                            <CardContent>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    {testimonial.testimonial}
                                </Typography>
                            </CardContent>
                            {/* <IconButton
                                sx={{
                                    position: 'absolute',
                                    bottom: 8,
                                    right: 8,
                                }}
                            >
                                <ArrowRightAlt />
                            </IconButton> */}
                        </Card>
                    </Grid>
                ))}


            </Grid>
        </Container>
    );
}
