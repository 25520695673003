import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { Link as RouterLink } from 'react-router-dom';

import Sitemark from './SitemarkIcon';

function AppAppBar() {
  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: 0,
        bgcolor: 'transparent',
        backgroundImage: 'none',
        mt: 2,
        width: '100%',
        maxWidth: '100%',
        left: 0,
        right: 0,
        overflowX: 'hidden',
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          px: { xs: 2, sm: 3 },
          width: '100%',
          maxWidth: '100%',
          overflowX: 'hidden',
        }}
      >
        <Toolbar
          variant="regular"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexShrink: 0,
            maxHeight: 40,
            width: '100%',
          }}
        >
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
            <Sitemark />
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 0.5,
              alignItems: 'center',
            }}
          >
            <RouterLink to="/apply" style={{ textDecoration: 'none' }}>
              <Button
                variant="contained"
                sx={{
                  background: 'transparent',
                  color: 'white',
                  fontWeight: 'bold',
                  borderRadius: 2,
                  border: 'none',
                  padding: { xs: '8px 16px', sm: '10px 20px' },
                  fontSize: { xs: '0.875rem', sm: '1rem' },
                  textTransform: 'none',
                  '&:hover': {
                    background: 'linear-gradient(90deg, #B22CB3 20%, #c1fe72 120%)',
                  },
                }}
              >
                For Merchants
              </Button>
            </RouterLink>
            <RouterLink to="/contact" style={{ textDecoration: 'none' }}>
              <Button
                variant="contained"
                sx={{
                  background: 'transparent',
                  color: 'white',
                  fontWeight: 'bold',
                  borderRadius: 2,
                  border: 'none',
                  padding: { xs: '8px 16px', sm: '10px 20px' },
                  fontSize: { xs: '0.875rem', sm: '1rem' },
                  textTransform: 'none',
                  '&:hover': {
                    background: 'linear-gradient(90deg, #B22CB3 20%, #c1fe72 120%)',
                  },
                }}
              >
                Contact
              </Button>
            </RouterLink>

          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;