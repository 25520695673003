import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Divider, styled } from '@mui/material';
import Footer from '../components/Footer';
import { logPageView } from '../firebaseApp';
import Sitemark from '../components/SitemarkIcon';

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  padding: theme.spacing(8, 0),
}));

const PrivacyPage = () => {
  React.useEffect(() => {
    logPageView('Privacy Page');
  }, []);

  return (
    <Container>
      <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
        <Sitemark />
      </Box>
      <StyledContainer maxWidth="md" >
        <Typography variant="body1" component="p">
          UPDATED: JUNE 16, 2024
        </Typography>
        <Typography variant="h4" component="h1" gutterBottom>
          Privacy Policy
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Typography variant="body1" paragraph>
            This Privacy Policy describes how HGLOW MEDIA Inc., doing business as Sinteli ("Sinteli," "we," "us," or "our"), collects, uses, and shares your information when you use our composable e-commerce solutions and services (the "Services"). By accessing or using our Services, you agree to the collection, use, and sharing of your information as described in this Privacy Policy.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            Information We Collect
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Personal Information:</strong> This includes information that identifies you personally, such as your name, email address, phone number, and payment information that you provide when you sign up for our Services or make a purchase.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Usage Information:</strong> We collect information about how you use our Services, including the types of services you use, the time and duration of your use, and other details about your interaction with our Services.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Device Information:</strong> Information about the device you use to access our Services, including hardware model, operating system, unique device identifiers, and mobile network information.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Location Information:</strong> We may collect information about your location when you use our Services, depending on your device settings. You may disable location services in your device settings.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            How We Use Your Information
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>To Provide and Improve Our Services:</strong> We use your information to provide the Services you request, personalize your experience, and improve our offerings.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Communication:</strong> We use your information to communicate with you about our Services, including updates, security alerts, and support messages.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Marketing:</strong> With your consent, we may use your information to send you promotional messages and content. You can opt out of receiving these communications at any time.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Analytics and Performance:</strong> We analyze how users interact with our Services to improve user experience and optimize our offerings.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            Sharing Your Information
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Service Providers:</strong> We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, email delivery, hosting services, and customer service.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Compliance and Safety:</strong> We may disclose your information if we believe it's necessary to comply with applicable laws, regulations, legal processes, or government requests, or to protect the rights, property, or safety of Sinteli, our users, or others.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Business Transfers:</strong> In the event of a merger, acquisition, reorganization, bankruptcy, or other similar event, your information may be transferred or shared as part of the transaction.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            Your Choices and Rights
          </Typography>
          <Typography variant="body1" paragraph>
            You have certain rights regarding the personal information we hold about you. These may include the rights to access, correct, delete, or restrict the use of your information. You can usually manage your information directly within your account settings or by contacting us directly.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            Data Security
          </Typography>
          <Typography variant="body1" paragraph>
            We implement reasonable security measures designed to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no security system is impenetrable, and we cannot guarantee the security of our databases or that your information will not be intercepted while being transmitted to us over the Internet.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            International Transfers
          </Typography>
          <Typography variant="body1" paragraph>
            Your information may be transferred to, and maintained on, computers located outside of your state, province, country, or other governmental jurisdiction where the privacy laws may not be as protective as those in your jurisdiction.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            Changes to This Privacy Policy
          </Typography>
          <Typography variant="body1" paragraph>
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date at the top.
          </Typography>

          <Typography variant="h6" component="h2" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions about this Privacy Policy or our privacy practices, please contact us at:
          </Typography>
          <Typography variant="body1" paragraph>
            HGLOW MEDIA Inc.<br />
            support@sinteli.com
          </Typography>
          <Typography variant="body1" paragraph>
            By using our Services, you acknowledge that you have read and understand this Privacy Policy.
          </Typography>
        </Box>
      </StyledContainer>

      <Box sx={{ bgcolor: 'background.default' }}>
        <Divider />
        <Footer />
      </Box>
    </Container>
  );
};

export default PrivacyPage;
