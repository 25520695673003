import * as React from 'react';
import { Typography, Container, Box, Divider, Grid, styled, CssBaseline, Stack, Card, CardMedia, CardContent } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import { logPageView } from '../../firebaseApp';
import getLPTheme from '../../getLPTheme';
import CommonAppBar from '../../components/CommonAppBar';
import Footer from '../../components/Footer';
import TextImageSection from '../services/components/TextImageSection';
import RequestServicesCard from '../services/components/RequestServicesCard';

const StyledContainer = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(8, 0),
}));

const features = [
    "Unified customer data management",
    "360-degree customer view",
    "Segmentation and personalization",
    "Real-time data synchronization",
    "Data privacy and compliance",
    "Integration with marketing and analytics tools",
    "Actionable customer insights",
];

const products = [
    'Product Information Management',
    'Order Management System',
    'Customer Data Platform',
];

const CDPPage = () => {
    const LPtheme = createTheme(getLPTheme('dark'));

    React.useEffect(() => {
        logPageView('CDP Page');
        window.scrollTo(0, 0);
    }, []);

    return (
        <ThemeProvider theme={LPtheme}>
            <Helmet>
                <title>Customer Data Platform (CDP) - Sinteli</title>
                <meta name="description" content="Unify and leverage your customer data with our powerful CDP solution. Gain a 360-degree view of your customers, enable personalized experiences, and drive business growth through data-driven insights." />
                <meta name="keywords" content="customer data platform, CDP, customer data management, personalization, customer insights" />
            </Helmet>

            <CommonAppBar mode={'dark'} />

            <CssBaseline />
            <Container sx={{ pb: 8 }}>
                <CommonAppBar mode={'dark'} />
                <StyledContainer sx={{ mb: 8, mx: 0, width: '100%' }}>
                    <Typography
                        variant="h2"
                        component="h1"
                        sx={{
                            mt: 8,
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: 'center',
                            fontSize: 'clamp(3rem, 10vw, 1.5rem)',
                            textAlign: 'center',
                        }}
                    >
                        Customer Data Platform (CDP)
                    </Typography>
                    <CardMedia
                        component="img"
                        image="/cdp_main.svg"
                        alt="Customer Data Platform (CDP)"
                        sx={{
                            objectFit: 'contain',
                            width: '100%',
                            objectPosition: 'center',
                            height: '600px',
                            mb: 4,
                        }}
                    />
                    <Typography variant="body1" paragraph>
                        Unify and leverage your customer data with our powerful CDP solution. Gain a 360-degree view of your customers, enable personalized experiences, and drive business growth through data-driven insights. Our CDP empowers you to effectively manage and utilize customer data across all touchpoints, delivering exceptional customer experiences and maximizing customer lifetime value.
                    </Typography>

                    <Box sx={{ mt: 8, mb: 8 }}>
                        <Grid container spacing={2.5}>
                            {features.map((feature, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Stack
                                        direction="column"
                                        component={Card}
                                        spacing={1}
                                        useFlexGap
                                        sx={{
                                            color: 'inherit',
                                            px: 3,
                                            py: 1,
                                            height: '100%',
                                            border: '1px solid',
                                            borderColor: 'hsla(220, 25%, 25%, .3)',
                                            background: 'transparent',
                                            backgroundColor: 'grey.900',
                                            boxShadow: 'none',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            alignContent: 'center',
                                        }}
                                    >
                                        <div>
                                            <Typography gutterBottom sx={{ fontWeight: 'medium' }} variant='h6'>
                                                {feature}
                                            </Typography>
                                        </div>
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    <Divider sx={{ my: 4 }} />

                    <TextImageSection
                        title='Unified Customer Data Management'
                        description="Our CDP solution enables you to collect, unify, and manage customer data from various sources, including website interactions, purchase history, customer service interactions, and more. By consolidating customer data into a single platform, you can gain a comprehensive understanding of your customers and deliver personalized experiences across all channels."
                        image="/cdm-banner.png"
                        textOnLeft={false}
                    />

                    <TextImageSection
                        title='Personalization and Segmentation'
                        description="Leverage the power of customer data to deliver highly targeted and personalized experiences. Our CDP allows you to segment your customers based on their behaviors, preferences, and attributes, enabling you to create tailored marketing campaigns, product recommendations, and customer journeys. Drive engagement, conversions, and customer loyalty through data-driven personalization."
                        image="/website_analytics.jpg"
                    />

                    <Grid container spacing={6} sx={{ py: 6, mb: 12 }}>
                        <Grid item xs={12} sm={6} md={6} key={0}>
                            <Card sx={{ height: '100%' }}>
                                <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        Real-Time Data Synchronization
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Ensure that your customer data is always up to date with real-time synchronization across all systems and touchpoints. Our CDP integrates seamlessly with your existing marketing, sales, and customer service tools, enabling you to access and utilize the most accurate and timely customer information. Make informed decisions and deliver consistent experiences based on real-time customer data.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} key={0}>
                            <Card sx={{ height: '100%' }}>
                                <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        Actionable Customer Insights
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Gain valuable insights into customer behavior, preferences, and trends with our CDP's advanced analytics capabilities. Identify high-value customer segments, predict customer churn, and uncover growth opportunities. Our CDP provides actionable insights that empower you to make data-driven decisions, optimize your marketing strategies, and improve customer retention and loyalty.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <RequestServicesCard services={products} />

                </StyledContainer>

                <Divider />
                <Footer />
            </Container>
        </ThemeProvider>
    );
};

export default CDPPage;
