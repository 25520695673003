import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Alert, CircularProgress, Divider, styled } from '@mui/material';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import CommonAppBar from '../components/CommonAppBar';
import { logError, logFormSubmission, logPageView } from '../firebaseApp';

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  padding: theme.spacing(8, 0),
}));

const RequestDemoPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  React.useEffect(() => {
    logPageView('RequestDemo Page');
  }, []);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({});
    setSubmissionError(null);
    setSubmissionSuccess(false);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const newErrors = {};

    if (formData.name.trim() === '') {
      newErrors.name = 'Name is required';
    }
    if (formData.email.trim() === '') {
      newErrors.email = 'Email is required';
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Invalid email address';
    }
    if (formData.company.trim() === '') {
      newErrors.company = 'Company is required';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch('https://api.airtable.com/v0/appXjuxWcT2V5S101/tblofBh9P2i9cdNJN', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`,
        },
        body: JSON.stringify({
          records: [
            {
              fields: {
                Name: formData.name,
                Email: formData.email,
                Company: formData.company,
              },
            },
          ],
        }),
      });

      if (response.ok) {
        setSubmissionSuccess(true);
        logFormSubmission('Request Demo', formData);
        setFormData({
          name: '',
          email: '',
          company: '',
        });
      } else {
        setSubmissionError('Form submission failed. Please try again.');
        logError('Request Demo Form', 'Form submission failed');
      }
    } catch (error) {
      setSubmissionError('An error occurred while submitting the form. Please try again.');
      logError('Request Demo Form', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container sx={{ py: 8 }}>
      <CommonAppBar mode={'dark'} />
      {submissionSuccess ? (
        <StyledContainer maxWidth="md" sx={{ mt: 8, mb: 8, textAlign: 'center' }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Thank you for your request!
          </Typography>
          <Typography variant="body1" paragraph>
            Your request for a demo has been received. Our team will be in touch with you shortly to schedule a personalized walkthrough.
          </Typography>
          <Button variant="contained" color="primary" onClick={() => navigate('/')}>
            Go back to the homepage
          </Button>
        </StyledContainer>
      ) : (
        <StyledContainer maxWidth="md" sx={{ mt: 8, mb: 8 }}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h4" component="h1" gutterBottom>
              Request a Free Demo
            </Typography>
            <Typography variant="body1" paragraph>
              Discover how our platform can streamline your ecommerce operations and drive growth. Request a free demo today, and our team will be in touch to schedule a personalized walkthrough.
            </Typography>
            <Box component="form" noValidate sx={{ mt: 4 }} onSubmit={handleSubmit}>
              <TextField
                required
                id="name"
                name="name"
                label="Name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={!!errors.name}
                helperText={errors.name}
              />
              <TextField
                required
                id="email"
                name="email"
                label="Email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={!!errors.email}
                helperText={errors.email}
              />
              <TextField
                required
                id="company"
                name="company"
                label="Company"
                value={formData.company}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={!!errors.company}
                helperText={errors.company}
              />
              {submissionError && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {submissionError}
                </Alert>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Request Demo'}
              </Button>
            </Box>
          </Box>
        </StyledContainer>
      )}

      <Box sx={{ bgcolor: 'background.default' }}>
        <Divider />
        <Footer />
      </Box>
    </Container>
  );
};

export default RequestDemoPage;
