import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import LandingPage from './LandingPage';
import AboutPage from './views/About';
import ContactPage from './views/Contact';
import EarlyAccessPage from './views/EarlyAccess';
import RequestDemoPage from './views/RequestDemo';
import FAQsPage from './views/Faqs';
import BlogPage from './views/Blog';
import CareersPage from './views/Careers';
import InvestorsPage from './views/Investors';
import TermsPage from './views/Terms';
import PrivacyPage from './views/Privacy';
import GDPRPage from './views/GDPR';
import SignUpPage from './views/SignUp';
import getLPTheme from './getLPTheme';
import './firebaseApp';
import OmnichannelArticle from './views/blog/OmnichannelArticle';
import OMSArticle from './views/blog/OMSArticle';
import PIMArticle from './views/blog/PIMArticle';
import CDMArticle from './views/blog/CDMArticle';
import EcommerceArticle from './views/blog/EcommerceArticle';
import ChallengesArticle from './views/blog/ChallengesArticle';
import EmbracingOmnichannelArticle from './views/blog/EmbracingOmnichannelArticle';
import DataSecurityArticle from './views/blog/DataSecurityArticle';
import BlogContainer from './views/blog/BlogContainer';
import ServicesPage from './views/ServicesPage';
import ConsultingPage from './views/services/ConsultingPage';
import CommerceAsAServicePage from './views/services/CommerceAsAServicePage';
import CustomDevelopmentPage from './views/services/CustomDevelopmentPage';
import DataIntegrationsPage from './views/services/DataIntegrationsPage';
import WebsiteAnalyticsPage from './views/services/WebsiteAnalyticsPage';
import PIMPage from './views/products/PIMPage';
import OMSPage from './views/products/OMSPage';
import CDPPage from './views/products/CDPPage';
import MerchantApplicationPage from './views/MerchantApplicationPage';
import DeleteAccountPage from './views/DeleteAccountPage';

function App() {
  const LPtheme = createTheme(getLPTheme('dark'));

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/early-access" element={<EarlyAccessPage />} />
          <Route path="/demo" element={<RequestDemoPage />} />
          <Route path="/faqs" element={<FAQsPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/investors" element={<InvestorsPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/gdpr" element={<GDPRPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          {/* <Route path="/partners" element={<PartnersPage />} /> */}
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/services/consulting" element={<ConsultingPage />} />
          <Route path="/services/commerce-as-a-service" element={<CommerceAsAServicePage />} />
          <Route path="/services/custom-development" element={<CustomDevelopmentPage />} />
          <Route path="/services/data-integration" element={<DataIntegrationsPage />} />
          <Route path="/services/website-analytics" element={<WebsiteAnalyticsPage />} />

          <Route path="/products/product-information-management" element={<PIMPage />} />
          <Route path="/products/order-management-system" element={<OMSPage />} />
          <Route path="/products/customer-data-platform" element={<CDPPage />} />

          <Route path="/blog/omnichannel-success" element={<OmnichannelArticle />} />
          <Route path="/blog/centralized-oms-benefits" element={<OMSArticle />} />
          <Route path="blog/unlocking-pim-power" element={<PIMArticle />} />
          <Route path="blog/harnessing-cdm-power" element={<CDMArticle />} />
          <Route path="blog/ecommerce-management-solution" element={<EcommerceArticle />} />
          <Route path="blog/ecommerce-challenges" element={<ChallengesArticle />} />
          <Route path="/blog/embracing-omnichannel" element={<EmbracingOmnichannelArticle />} />
          <Route path="/blog/data-security-in-ecommerce" element={<DataSecurityArticle />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:headline" element={<BlogContainer />} />
          <Route path="/delete-account" element={<DeleteAccountPage />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/apply" element={<MerchantApplicationPage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
