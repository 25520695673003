import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { Card, CardContent, Divider, List, ListItem } from '@mui/material';
import CTA from '../../components/CTA';
import Footer from '../../components/Footer';
import getLPTheme from '../../getLPTheme';
import omsBanner from '../../assets/img/oms-banner.png';
import { Helmet } from 'react-helmet';
import CommonAppBar from '../../components/CommonAppBar';
import { logPageView } from '../../firebaseApp';

const StyledContainer = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(8, 0),
}));

const BannerContainer = styled('div')(({ theme }) => ({
    backgroundImage: `url(${omsBanner})`,
    borderRadius: theme.shape.borderRadius,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '400px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
    ...theme.applyStyles('dark', {
        boxShadow: '0 0 24px 12px hsla(267, 100%, 35%, 0.2)',
        outlineColor: 'hsla(210, 100%, 80%, 0.1)',
    }),
}));

const OMSArticle = () => {
    const LPtheme = createTheme(getLPTheme('dark'));

    React.useEffect(() => {
        logPageView('OMSArticle Page');
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);

    const articleContent = (
        <>
            <BannerContainer alt="Centralized Order Management System" />

            <Typography variant="body2" paragraph>
                June 5, 2024
            </Typography>
            <Typography
                variant="h1"
                component="h1"
                gutterBottom
                sx={{ color: 'white' }}
            >
                Streamlining Business Operations with a Centralized Order Management System
            </Typography>

            <Divider
                sx={{
                    backgroundColor: 'white',
                    width: '100%',
                    height: 1,
                    marginBottom: 2,
                }}
            />

            <Typography variant="body1" paragraph fontSize={18} my={5}>
                In today's fast-paced business environment, efficiently managing orders across multiple channels is crucial for success. A centralized Order Management System (OMS) can revolutionize the way businesses handle their order processing, fulfillment, and customer service. Let's explore the key benefits of implementing a centralized OMS.
            </Typography>

            <Card sx={{ mb: 4, px: 2 }}>
                <CardContent>
                    <Typography variant="h5" component="h2" gutterBottom mt={2}>
                        What is a Centralized Order Management System?
                    </Typography>
                    <Typography variant="body1" paragraph mt={2}>
                        A centralized Order Management System is a software solution that consolidates and streamlines order processing from various sales channels into a single, unified platform. It enables businesses to manage orders, inventory, fulfillment, and customer data efficiently, regardless of the source of the order.
                    </Typography>
                </CardContent>
            </Card>

            <Typography variant="h2" component="h2" gutterBottom mt={10}>
                Benefits of a Centralized Order Management System
            </Typography>

            <Divider />
            <List>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        <strong>Improved Order Accuracy:</strong> With a centralized OMS, businesses can eliminate manual data entry and reduce the risk of errors. Orders from various channels are automatically consolidated, ensuring accurate and consistent information across the entire fulfillment process.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        <strong>Enhanced Inventory Visibility:</strong> A centralized OMS provides real-time visibility into inventory levels across all sales channels. This enables businesses to make informed decisions about stock replenishment, avoid overselling, and optimize inventory allocation.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        <strong>Streamlined Fulfillment:</strong> With a centralized OMS, businesses can automate and streamline their fulfillment processes. Orders can be automatically routed to the appropriate warehouse or fulfillment center based on predefined rules, ensuring efficient and timely order processing.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        <strong>Improved Customer Experience:</strong> A centralized OMS enables businesses to provide accurate and timely order updates to customers. Customers can easily track their orders, receive notifications, and access order history across all channels, leading to enhanced customer satisfaction and loyalty.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        <strong>Increased Operational Efficiency:</strong> By automating and centralizing order management processes, businesses can reduce manual workload, minimize errors, and optimize resource allocation. This leads to increased operational efficiency, cost savings, and improved overall business performance.
                    </Typography>
                </ListItem>
            </List>

            <Typography variant="h2" component="h2" gutterBottom mt={10}>
                Case Studies
            </Typography>

            <Divider />
            <Card sx={{ mb: 4, px: 2, mt: 4 }}>
                <CardContent>
                    <Typography variant="h5" component="h3" gutterBottom mt={2}>
                        Case Study 1: Adidas
                    </Typography>
                    <Typography variant="body1" paragraph fontSize={18}>
                        Adidas, a global sportswear giant, implemented a centralized OMS to streamline its order management processes across multiple sales channels. By consolidating orders from its website, mobile app, and retail stores, Adidas achieved a 30% reduction in order processing time and a 20% increase in order accuracy. The centralized OMS also enabled Adidas to optimize inventory allocation and improve customer satisfaction through real-time order tracking and notifications.
                    </Typography>
                </CardContent>
            </Card>

            <Card sx={{ mb: 4, px: 2, mt: 4 }}>
                <CardContent>
                    <Typography variant="h5" component="h3" gutterBottom mt={2}>
                        Case Study 2: Sephora
                    </Typography>
                    <Typography variant="body1" paragraph fontSize={18}>
                        Sephora, a leading beauty retailer, implemented a centralized OMS to enhance its omnichannel capabilities. With a unified view of inventory across online and offline channels, Sephora was able to optimize stock levels, reduce out-of-stock situations, and improve order fulfillment efficiency. The centralized OMS also enabled Sephora to offer seamless experiences such as buy online, pick up in-store (BOPIS) and real-time inventory visibility to customers.
                    </Typography>
                </CardContent>
            </Card>

            <Typography variant="h2" component="h2" gutterBottom mt={10}>
                Implementing a Centralized Order Management System
            </Typography>

            <Divider />
            <Typography variant="body1" paragraph fontSize={18} mt={2}>
                Implementing a centralized OMS requires careful planning and execution. Businesses should assess their current order management processes, identify pain points, and define their requirements for a centralized system. Partnering with a reputable OMS provider can help ensure a smooth implementation and seamless integration with existing systems.
            </Typography>

            <Typography variant="h2" component="h2" gutterBottom mt={10}>
                Conclusion
            </Typography>

            <Typography variant="body1" paragraph fontSize={18}>
                A centralized Order Management System is a game-changer for businesses looking to streamline their order management processes, improve operational efficiency, and enhance customer experience. By consolidating orders from multiple channels, providing real-time inventory visibility, and automating fulfillment processes, a centralized OMS empowers businesses to stay competitive in today's dynamic market.
            </Typography>
            <Typography variant="body1" paragraph fontSize={18}>
                Embrace the power of a centralized OMS and unlock the full potential of your business. Streamline your operations, delight your customers, and drive growth with a unified order management solution.
            </Typography>
        </>
    );

    return (
        <ThemeProvider theme={LPtheme}>
            <Helmet>
                <title>Streamlining Business Operations with a Centralized Order Management System | Sinteli Blog</title>
                <meta name="description" content="Discover how a centralized Order Management System (OMS) can revolutionize your business operations. Improve order accuracy, enhance inventory visibility, streamline fulfillment, and deliver exceptional customer experiences. Learn the benefits of implementing a centralized OMS and unlock the full potential of your business." />
                <meta name="keywords" content="order management system, OMS, centralized OMS, order processing, inventory management, fulfillment, customer experience, operational efficiency" />
            </Helmet>

            <CssBaseline />
            <CommonAppBar mode={'dark'} />
            <StyledContainer maxWidth="md" sx={{ mt: 10, px: 3 }}>
                {articleContent}
            </StyledContainer>
            <Box sx={{ bgcolor: 'background.default' }}>
                <CTA />
                <Divider />
                <Footer />
            </Box>
            <script type="application/ld+json">
                {`
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "headline": "Streamlining Business Operations with a Centralized Order Management System",
      "image": "${omsBanner}",
      "author": {
        "@type": "Person",
        "name": "Sinteli Team"
      },
      "datePublished": "2024-07-05",
      "dateModified": "2024-07-05",
      "publisher": {
        "@type": "Organization",
        "name": "Sinteli",
        "logo": {
          "@type": "ImageObject",
          "url": "https://sinteli.com/logo.png"
        }
      },
      "description": "Discover how a centralized Order Management System (OMS) can revolutionize your business operations. Improve order accuracy, enhance inventory visibility, streamline fulfillment, and deliver exceptional customer experiences. Learn the benefits of implementing a centralized OMS and unlock the full potential of your business."
    }
  `}
            </script>
        </ThemeProvider>
    );
};

export default OMSArticle;
