import * as React from 'react';
import { Typography, Container, Box, Divider, Grid, styled, CssBaseline, Stack, Card, CardMedia, CardContent } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import { logPageView } from '../../firebaseApp';
import getLPTheme from '../../getLPTheme';
import CommonAppBar from '../../components/CommonAppBar';
import Footer from '../../components/Footer';
import RequestServicesCard from './components/RequestServicesCard';
import BlogSnippets from './components/BlogSnippets';
import TextImageSection from './components/TextImageSection';

const StyledContainer = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(8, 0),
}));

const analytics = [
    "User behavior tracking and analysis",
    "Conversion rate optimization",
    "A/B testing and experimentation",
    "Customer journey mapping",
    "Funnel analysis and optimization",
    "Real-time website monitoring",
    "Custom reporting and dashboards",
];

const WebsiteAnalyticsPage = () => {
    const LPtheme = createTheme(getLPTheme('dark'));

    React.useEffect(() => {
        logPageView('Website Analytics Page');
        window.scrollTo(0, 0);
    }, []);

    return (
        <ThemeProvider theme={LPtheme}>
            <Helmet>
                <title>Website Analytics - Sinteli</title>
                <meta name="description" content="Gain valuable insights into your website's performance and user behavior with our comprehensive analytics solutions. Make data-driven decisions to optimize your online presence and drive conversions." />
                <meta name="keywords" content="website analytics, performance, user behavior, optimization, conversions" />
            </Helmet>

            <CommonAppBar mode={'dark'} />

            <CssBaseline />
            <Container sx={{ pb: 8 }}>
                <CommonAppBar mode={'dark'} />
                <StyledContainer sx={{ mb: 8, mx: 0, width: '100%' }}>
                    <CardMedia
                        component="img"
                        image="/website_analytics.jpg"
                        alt="Website Analytics"
                        sx={{
                            objectFit: 'cover',
                            width: '100%',
                            objectPosition: '0 0',
                            height: '600px',
                            mb: 4,
                        }}
                    />

                    <Typography variant="h2" component="h1" gutterBottom>
                        Website Analytics
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Gain valuable insights into your website's performance and user behavior with our comprehensive analytics solutions. Make data-driven decisions to optimize your online presence and drive conversions. Our analytics services help you understand your audience, identify areas for improvement, and measure the success of your e-commerce strategies.
                    </Typography>

                    <Box sx={{ mt: 8, mb: 8 }}>
                        <Grid container spacing={2.5}>
                            {analytics.map((item, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Stack
                                        direction="column"
                                        component={Card}
                                        spacing={1}
                                        useFlexGap
                                        sx={{
                                            color: 'inherit',
                                            px: 3,
                                            py: 1,
                                            height: '100%',
                                            border: '1px solid',
                                            borderColor: 'hsla(220, 25%, 25%, .3)',
                                            background: 'transparent',
                                            backgroundColor: 'grey.900',
                                            boxShadow: 'none',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            alignContent: 'center',
                                        }}
                                    >
                                        <div>
                                            <Typography gutterBottom sx={{ fontWeight: 'medium' }} variant='h6'>
                                                {item}
                                            </Typography>
                                        </div>
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    <Divider sx={{ my: 4 }} />

                    <TextImageSection
                        title='User Behavior Analysis'
                        description="Our analytics solutions provide deep insights into user behavior on your website. Track user interactions, clicks, scrolls, and more to understand how visitors navigate your site and engage with your content. Identify patterns, uncover pain points, and optimize the user experience to improve engagement and conversions."
                        image="/cdm-banner.png"
                        textOnLeft={false}
                    />

                    <TextImageSection
                        title='Conversion Rate Optimization'
                        description="Maximize your website's conversion potential with our data-driven optimization strategies. Analyze user behavior at each stage of the conversion funnel to identify drop-off points and optimize the user journey. Conduct A/B tests to experiment with different layouts, content, and calls-to-action to determine the most effective variations for driving conversions."
                        image="/article-8-banner.png"
                    />

                    <Grid container spacing={6} sx={{ py: 6 }}>
                        <Grid item xs={12} sm={6} md={6} key={0}>
                            <Card sx={{ height: '100%' }}>
                                <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        Real-Time Monitoring
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Stay on top of your website's performance with real-time monitoring and alerts. Our analytics solutions provide instant visibility into website traffic, user behavior, and key performance indicators. Quickly identify and resolve issues, such as slow page load times or high bounce rates, to ensure a seamless user experience and maintain optimal performance.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} key={0}>
                            <Card sx={{ height: '100%' }}>
                                <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        Custom Reporting and Dashboards
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Access the data you need to make informed decisions with our customizable reporting and dashboards. We tailor analytics reports to your specific business requirements, providing you with the most relevant metrics and insights. Visualize your data through intuitive dashboards that allow you to monitor key performance indicators at a glance and share insights with stakeholders.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <BlogSnippets title={'Related Blog Posts'} />
                    <RequestServicesCard services={analytics} />

                </StyledContainer>

                <Divider />
                <Footer />
            </Container>
        </ThemeProvider>
    );
};

export default WebsiteAnalyticsPage;
