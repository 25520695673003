import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Footer from '../components/Footer';
import { Alert, CircularProgress, Divider, styled } from '@mui/material';
import CommonAppBar from '../components/CommonAppBar';
import { logError, logFormSubmission, logPageView } from '../firebaseApp';

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  padding: theme.spacing(8, 0),
}));

const EarlyAccessPage = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  React.useEffect(() => {
    logPageView('EarlyAccess Page');
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setEmailError('');
    setSubmissionError(null);
    setSubmissionSuccess(false);

    if (email.trim() === '') {
      setEmailError('Please enter your email address');
      return;
    }

    // Email validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address');
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch('https://api.airtable.com/v0/appboi3QhIuRynMzo/tblwlvovfKcT4sP4i', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`,
        },
        body: JSON.stringify({
          records: [
            {
              fields: {
                Email: email,
              },
            },
          ],
        }),
      });

      if (response.ok) {
        setSubmissionSuccess(true);
        logFormSubmission('Early Access', { email });
        setEmail('');
      } else {
        setSubmissionError('Submission failed. Please try again.');
        logError('Early Access Form', 'Submission failed');
      }
    } catch (error) {
      setSubmissionError('An error occurred while submitting. Please try again.');
      logError('Early Access Form', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container sx={{ py: 8 }}>
      <CommonAppBar mode={'dark'} />
      <StyledContainer maxWidth="md" sx={{ mt: 4, mb: 8 }}>
        {submissionSuccess ? (
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h4" component="h1" gutterBottom>
              Thank you for joining!
            </Typography>
            <Typography variant="body1" paragraph>
              We appreciate your interest in our early access program. Our team will be in touch with you shortly to provide further details and instructions.
            </Typography>
            <Button variant="contained" component="a" href="/" sx={{ mt: 4 }}>
              Return to Homepage
            </Button>
          </Box>
        ) : (
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h4" component="h1" gutterBottom>
              Join Our Early Access Program
            </Typography>
            <Typography variant="body1" paragraph>
              Be among the first to experience our cutting-edge platform and shape the future of ecommerce management. Your valuable insights and feedback will play a crucial role in refining our product to perfection.
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 4 }}>
              <TextField
                required
                id="email"
                name="email"
                label="Email"
                type="email"
                fullWidth
                margin="normal"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                error={!!emailError}
                helperText={emailError}
              />
              {submissionError && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {submissionError}
                </Alert>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Join Now'}
              </Button>
            </Box>
          </Box>
        )}
      </StyledContainer>

      <Box sx={{ bgcolor: 'background.default' }}>
        <Divider />
        <Footer />
      </Box>
    </Container>
  );
};

export default EarlyAccessPage;
