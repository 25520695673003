import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const GDPRPage = () => {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        GDPR Compliance
      </Typography>
      <Box sx={{ mt: 4 }}>
        <Typography variant="body1" paragraph>
          Our company is committed to complying with the General Data Protection
          Regulation (GDPR) and ensuring the privacy and security of personal
          data.
        </Typography>
        {/* Add your GDPR compliance information here */}
      </Box>
    </Container>
  );
};

export default GDPRPage;
