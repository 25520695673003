import * as React from 'react';
import PropTypes from 'prop-types';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import Footer from './components/Footer';
import getLPTheme from './getLPTheme';
import { Helmet } from 'react-helmet';
import { logPageView } from './firebaseApp';
import DetailedFeatures from './components/DetailedFeatures';
import CommerceServiceCTA from './components/CommerceServiceCTA';
import { motion } from 'framer-motion';
import Highlights from './components/Highlights';


function ToggleCustomTheme({ showCustomTheme, toggleCustomTheme }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100dvw',
        position: 'fixed',
        bottom: 24,
      }}
    >
      <ToggleButtonGroup
        color="primary"
        exclusive
        value={showCustomTheme}
        onChange={toggleCustomTheme}
        aria-label="Toggle design language"
        sx={{
          backgroundColor: 'background.default',
          '& .Mui-selected': {
            pointerEvents: 'none',
          },
        }}
      >
        <ToggleButton value>
          <AutoAwesomeRoundedIcon sx={{ fontSize: '20px', mr: 1 }} />
          Custom theme
        </ToggleButton>
        <ToggleButton value={false}>Material Design 2</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}

ToggleCustomTheme.propTypes = {
  showCustomTheme: PropTypes.shape({
    valueOf: PropTypes.func.isRequired,
  }).isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
};

export default function LandingPage() {
  const [mode, setMode] = React.useState('dark');
  const LPtheme = createTheme(getLPTheme(mode));

  React.useEffect(() => {
    logPageView('Home Page');
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  return (
    <ThemeProvider theme={LPtheme}>
      <Helmet>
        <title>Sinteli - A Next Gen Loyalty Platform for DTC Brands</title>
        <meta name="description"
          content="Sinteli is loyalty and social platform that redefines how businesses reach their audience and drive growth." />
        <meta name="keywords" content="digital advertising, Canadian businesses, ad platform, marketing solutions, fair advertising, transparent ads, innovative ad tools, loyalty platform, social platform, DTC brands, customer retention, growth, loyalty" />
      </Helmet>

      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />

      <main className="main" style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        width: '100%',
        maxWidth: '100%',
        overflowX: 'hidden'
      }}>
        <div className="mainDiv">
          <h1 className="subtitle" style={{
            fontSize: 'clamp(1.25rem, 4vw, 2rem)',
          }}>
            <span>Your new path to</span>
          </h1>
          <h1 className="bigTitle" style={{
            marginTop: '10px',
            fontSize: 'clamp(2.5rem, 8vw, 5rem)',
          }}>
            <span>Digital Growth</span>
          </h1>
          <div className='comingSoonButton' style={{
            fontSize: 'clamp(1rem, 3vw, 1.5rem)',
            display: 'inline-block',
            padding: '10px 20px',
          }}>Coming Soon
          </div>
        </div>
        <div className="line" />
      </main>

      <CommerceServiceCTA />

      <Footer />

      {/* <ToggleCustomTheme
        showCustomTheme={showCustomTheme}
        toggleCustomTheme={toggleCustomTheme}
      /> */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "Sinteli",
            "description": "Sinteli is loyalty and social platform that redefines how businesses reach their audience and drive growth.",
            "brand": {
              "@type": "Brand",
              "name": "Sinteli"
            },
            "offers": {
              "@type": "Offer",
              "priceCurrency": "CAD",
              "price": "0",
              "availability": "https://schema.org/InStock"
            }
          }
        `}
      </script>
    </ThemeProvider>
  );
}
