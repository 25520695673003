import React from 'react';
import logo from '../logo.png';
import { Link } from 'react-router-dom';

const SitemarkIcon = () => {
  return (
    <Link to="/">
      <img
        src={logo}
        alt="Sitemark Logo"
        style={{
          width: '40px',
          borderRadius: '10px',
          border: '1px solid #2d2a29',
        }}
      />
    </Link>
  );
}

export default SitemarkIcon;