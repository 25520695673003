import * as React from 'react';
import { Typography, Container, Box, Divider, Grid, styled, CssBaseline, Stack, Card, CardMedia, CardContent } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import { logPageView } from '../../firebaseApp';
import getLPTheme from '../../getLPTheme';
import CommonAppBar from '../../components/CommonAppBar';
import Footer from '../../components/Footer';
import RequestServicesCard from './components/RequestServicesCard';
import BlogSnippets from './components/BlogSnippets';
import TextImageSection from './components/TextImageSection';

const StyledContainer = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(8, 0),
}));

const specialties = [
    "E-commerce strategy development",
    "User experience (UX) optimization",
    "Conversion rate optimization (CRO)",
    "Marketing and customer acquisition strategies",
    "Data analytics and insights",
    "Technology stack evaluation and recommendations",
    "Operational efficiency and process improvement",
]

const ConsultingPage = () => {
    const LPtheme = createTheme(getLPTheme('dark'));

    React.useEffect(() => {
        logPageView('Consulting Page');
        window.scrollTo(0, 0);
    }, []);

    return (
        <ThemeProvider theme={LPtheme}>
            <Helmet>
                <title>E-Commerce Consulting - Sinteli</title>
                <meta name="description" content="Our experienced e-commerce consultants provide strategic guidance and insights to help you optimize your online store, increase conversions, and achieve your business goals. Trust our expertise to overcome challenges and drive growth." />
                <meta name="keywords" content="e-commerce consulting, online store optimization, conversion rate, business growth, trust, security" />
            </Helmet>

            <CommonAppBar mode={'dark'} />

            <CssBaseline />
            <Container sx={{ pb: 8 }}>
                <CommonAppBar mode={'dark'} />
                <StyledContainer sx={{ mb: 8, mx: 0, width: '100%' }}>
                    <CardMedia
                        component="img"
                        image="/banner-consulting.svg"
                        alt="A list of common ecommerce pain points"
                        sx={{
                            objectFit: 'cover',
                            width: '100%',
                            objectPosition: '0 0',
                            height: '600px',
                            mb: 4,
                        }}
                    />

                    <Typography variant="h2" component="h1" gutterBottom>
                        Enterprise-Grade Commerce Solutions
                    </Typography>
                    <Typography variant="body1" paragraph>
                        In today's competitive digital landscape, having a strong online presence is crucial for the success of your business. Our team of experienced e-commerce consultants is here to help you navigate the challenges and optimize your online store for maximum growth and profitability.
                    </Typography>

                    <Box sx={{ mt: 8, mb: 8 }}>
                        {/* <Typography variant="h3" gutterBottom>
                            Our Consulting Services Include:
                        </Typography> */}
                        <Grid container spacing={2.5}>
                            {specialties.map((specialty, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Stack
                                        direction="column"
                                        component={Card}
                                        spacing={1}
                                        useFlexGap
                                        sx={{
                                            color: 'inherit',
                                            px: 3,
                                            py: 1,
                                            height: '100%',
                                            border: '1px solid',
                                            borderColor: 'hsla(220, 25%, 25%, .3)',
                                            background: 'transparent',
                                            backgroundColor: 'grey.900',
                                            boxShadow: 'none',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            alignContent: 'center',
                                        }}
                                    >
                                        <div>
                                            <Typography gutterBottom sx={{ fontWeight: 'medium' }}
                                                variant='h6'
                                            >
                                                {specialty}
                                            </Typography>
                                        </div>
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>

                    </Box>

                    <Divider sx={{ my: 4 }} />

                    <TextImageSection
                        title='Overcome Pain Points'
                        description="We understand the common pain points e-commerce businesses face, such as low conversion rates, high cart abandonment, and difficulty in attracting and retaining customers. Our consultants will work closely with you to identify and address these challenges, providing tailored solutions to optimize your online store's performance."
                        image="/commerce_pain_points_trans2.svg"
                        textOnLeft={false}
                    />

                    <TextImageSection
                        title='Prioritize Security and Trust'
                        description="Building trust with your customers is essential for the success of your e-commerce business. Our consultants will guide you in implementing robust security measures to protect sensitive customer data and transactions. We'll also help you establish trust through transparent communication, reliable fulfillment, and exceptional customer service."
                        image="/security_and_trust2.svg"
                    />


                    <Grid container spacing={6} sx={{ py: 6 }} >
                        <Grid item xs={12} sm={6} md={6} key={0}>
                            <Card
                                // onClick={() => handleCardClick(service.path)}
                                sx={{
                                    height: '100%',
                                }}>
                                <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        Agile Methodology
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        We follow an agile approach to e-commerce consulting, allowing us to quickly adapt to changing market conditions and customer needs. Our consultants work in iterative cycles, continuously monitoring and optimizing your online store's performance. This agile methodology ensures that you stay responsive and competitive in the fast-paced e-commerce environment.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} key={0}>
                            <Card
                                // onClick={() => handleCardClick(service.path)}
                                sx={{
                                    height: '100%',
                                }}>

                                <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        Meet Evolving Demands
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        The e-commerce landscape is constantly evolving, with changing customer expectations and new technologies emerging. Our consultants stay up-to-date with the latest trends and best practices to ensure your online store meets the evolving demands of your target audience. We'll help you implement innovative strategies to stay ahead of the competition.                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <BlogSnippets title={'Related Blog Posts'} />
                    <RequestServicesCard services={specialties} />

                </StyledContainer>

                <Divider />
                <Footer />
            </Container >
        </ThemeProvider >
    );
};

export default ConsultingPage;
