import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';

import { logComponentHidden, logComponentVisible, logFormSubmission } from '../firebaseApp';
import { CircularProgress } from '@mui/material';

const StyledBox = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  width: '100%',
  height: '100%',
  // marginTop: theme.spacing(8),
  // borderRadius: theme.shape.borderRadius,
  // outline: '1px solid',
  // boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
  // outlineColor: 'hsla(220, 25%, 80%, 0.5)',
  // overflow: 'hidden', // Added to hide the image outside the rounded corners
  // backgroundSize: 'cover',
  // [theme.breakpoints.up('sm')]: {
  //   marginTop: theme.spacing(10),
  //   height: 700,
  // },
  // ...theme.applyStyles('dark', {
  //   boxShadow: '0 0 24px 12px hsla(267, 100%, 35%, 0.2)',
  //   outlineColor: 'hsla(210, 100%, 80%, 0.1)',
  // }),
}));

export default function Hero() {
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState('');
  const [loadingEmailSubmit, setLoadingEmailSubmit] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);

  const componentRef = React.useRef(null);
  const visibleTimestampRef = React.useRef(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          visibleTimestampRef.current = new Date().getTime();
          logComponentVisible('Hero Component');
        } else {
          const hiddenTimestamp = new Date().getTime();
          const durationMs = hiddenTimestamp - visibleTimestampRef.current;
          logComponentHidden('Hero Component', durationMs);
        }
      });
    });

    const currentRef = componentRef.current; // Copying componentRef.current to a variable

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError('');
  };

  const handleStartNow = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email.trim() === '') {
      setEmailError('Please enter your email address');
    } else if (!emailRegex.test(email)) {
      setEmailError('Invalid email address');
    } else {
      logFormSubmission('Hero', { email });
      try {
        setLoadingEmailSubmit(true);
        const response = await fetch('https://api.airtable.com/v0/appbw9FhuHO7M735N/tbl7WoukH2uYDkXin', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_AIRTABLE_TOKEN}`,
          },
          body: JSON.stringify({
            records: [
              {
                fields: {
                  Email: email,
                  Source: 'Landing Page Hero',
                },
              },
            ],
          }),
        });

        if (response.ok) {
          logFormSubmission('Hero', { email });
          setEmail('');
          setShowSuccessMessage(true);
          // Hide the success message after 5 seconds
          setTimeout(() => setShowSuccessMessage(false), 5000);
        } else {
          setEmailError('Failed to join waitlist. Please try again.');
        }

      } catch (error) {
        setEmailError('An error occurred. Please try again.');
      } finally {
        setLoadingEmailSubmit(false);
      }
    }
  };

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundColor: '#000d24',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(220, 25%, 10%), transparent)',

      })}
    >

      <Container
        maxWidth={false}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 7, sm: 10 },
          px: { xs: 2, sm: 3 },
          width: '100%',
          maxWidth: '100%',
        }}
      >

        <Stack
          spacing={2}
          useFlexGap
          sx={{
            alignItems: 'center',
            width: { xs: '100%', sm: '70%' },
            backgroundImage: `${process.env.PUBLIC_URL}/hero-banner.svg`,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
        >

          <Typography
            variant="h1"
            sx={{
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              fontSize: 'clamp(1.5rem, 10vw, 2.5rem)',
              textAlign: 'center',
              mt: 12,
              color: 'white',
            }}
          >
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'inherit',
                backgroundImage: 'linear-gradient(90deg, #F1706D, #FFD66E)',
                backgroundClip: 'text',
                textFillColor: 'transparent',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Engage
            </Typography>
            {' '}with your Best Customers


            {/* better by building<span style={{ color: '#FF7A70' }}> reliable </span>
            data pipelines */}
            <br />

          </Typography>
          {/* <Typography
            variant="h2"
            sx={{
              textAlign: 'center',
              fontSize: 'clamp(2.5rem, 10vw, 3rem)',
              mt: 0,
              mb: 2,
            }}
          >Your Marketing Team</Typography> */}
          <Typography
            variant="h6"
            sx={{
              textAlign: 'center',
              mt: 0,
              fontSize: 'clamp(0.9rem, 2vw, 1.1rem)',
              maxWidth: '80%',
              mx: 'auto',
              color: 'white',
            }}
          >
            Sinteli helps global brands like yours reduce churn, enhance customer engagement, and maximize lifetime value by delivering actionable insights tailored to your unique customer base.            {/* <span
              style={{
                fontWeight: 'bold',

                // textDecoration: 'underline',
                background: 'linear-gradient(90deg, #FFD66E 0%, #FFD66E 100%)',
              }}>Equip your Marketing Team</span> with Sinteli’s AI-powered platform and make campaign management and client reporting effortless.  */}
            {/* <br /><br />Sign up now and get <span style={{ fontWeight: 'bold' }}> early access</span>. */}
          </Typography>

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={1}
            useFlexGap
            sx={{ width: { xs: '100%', sm: 'auto' } }}
          >
            <InputLabel htmlFor="email-hero" sx={visuallyHidden}>
              Email
            </InputLabel>
            <TextField
              id="email-hero"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Enter your company email address"
              placeholder="Company email address"
              value={email}
              onChange={handleEmailChange}
              error={!!emailError}
              helperText={emailError}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2
                }
              }}
              slotProps={{
                htmlInput: {
                  autoComplete: 'off',
                  'aria-label': 'Enter your company email address',
                },
              }}
            />
            <Button
              variant="contained"
              onClick={handleStartNow}
              disabled={loadingEmailSubmit}
              sx={{
                background: 'linear-gradient(90deg, #FF5A60 0%, #001842 100%)',
                color: 'white',
                fontWeight: 'bold',
                borderRadius: 2,
                border: 'none',
                padding: '10px 20px',
                fontSize: '1rem',
                textTransform: 'none',
                '&:hover': {
                  background: 'linear-gradient(90deg, #FF5A60 20%, #001842 120%)',
                },
              }}
            >
              Get Started For Free {loadingEmailSubmit && <CircularProgress size={20} sx={{ ml: 1 }} />}
            </Button>



          </Stack>
          {showSuccessMessage && (
            <Typography
              variant="body2"
              sx={{
                color: 'success.main',
                mt: 2,
                textAlign: 'center',
              }}
            >
              Thank you for joining our waitlist! We'll be in touch soon.
            </Typography>
          )}
          {/* <Typography variant="caption" sx={{ textAlign: 'center' }}>
            By clicking &quot;Start now&quot; you agree to our&nbsp;
            <Link href="/terms" color="primary">
              Terms & Conditions
            </Link>
            .
          </Typography> */}
        </Stack>
        <StyledBox
          sx={{
            overflow: 'hidden',
            // position: 'relative',
          }}
        >
          <Box
            component="img"
            src={'https://imagedelivery.net/f7ix1vCCw-qhLpZb3c_8yw/484d5ac8-23fd-40b7-6951-18d580161400/public'}
            alt="Hero Image"
            sx={{
              display: 'block',
              width: { xs: '150%', sm: '120%', md: '100%' },
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
              margin: 'auto',
              transform: { xs: 'translateX(-16.67%)', sm: 'translateX(-8.33%)', md: 'none' },
            }}
          />
        </StyledBox>

      </Container>
    </Box>
  );
}
