import * as React from 'react';
import { Box, Typography, Card, CardContent, CardMedia } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import Carousel from 'react-material-ui-carousel';
import { useNavigate } from 'react-router-dom';

const blogs = [
    {
        id: 1,
        title: 'The Future of Retail: Why Omnichannel Businesses Are Thriving',
        description: 'In today\'s fast-paced and digitally-driven world, consumers expect seamless and consistent shopping experiences across all platforms. This expectation has given rise to the concept of omnichannel retailing, where businesses integrate their ...',
        image: 'omni3.webp',
        path: '/blog/omnichannel-success',
    },
    {
        id: 2,
        title: '5 Key Challenges in Ecommerce Management and How to Overcome Them',
        description: 'Discover the five key challenges in ecommerce management and how our innovative solution can help you overcome them. From disjointed order management to inconsistent product data and inefficient customer handling, learn how to streamline your operations, drive growth, and achieve ecommerce success.',
        image: 'article-4-banner.png',
        path: '/blog/ecommerce-challenges',
    },
    {
        id: 3,
        title: 'Harnessing the Power of Customer Data Management (CDM)',
        description: 'Discover how Customer Data Management (CDM) can revolutionize your business operations. Collect, organize, and utilize customer data to deliver personalized experiences, gain valuable insights, enhance marketing effectiveness, and drive business growth. Learn the benefits of implementing a CDM solution and unlock the full potential of your customer data.',
        image: 'cdm-banner.png',
        path: '/blog/harnessing-cdm-power',
    },
    {
        id: 4,
        title: 'Streamlining Business Operations with a Centralized Order Management System',
        description: 'Discover how a centralized Order Management System (OMS) can revolutionize your business operations. Improve order accuracy, enhance inventory visibility, streamline fulfillment, and deliver exceptional customer experiences. Learn the benefits of implementing a centralized OMS and unlock the full potential of your business.',
        image: 'oms-banner.png',
        path: '/blog/centralized-oms-benefits',
    },
    {
        id: 5,
        title: 'The Future of Ecommerce: Embracing Omnichannel Strategies',
        description: 'Discover the importance of omnichannel strategies in the future of ecommerce. Learn Sinteli can help businesses create a seamless and integrated shopping experience across multiple channels, driving sales growth and customer loyalty. Explore case studies of successful omnichannel implementations and unlock the full potential of your business.',
        image: 'article-2-banner.png',
        path: '/blog/embracing-omnichannel',
    },
];

const BlogSnippets = ({ title }) => {
    const navigate = useNavigate();

    const handleBlogClick = (blog) => {
        navigate(`${blog.path}`, { state: { blog } });
    }
    return (
        <Box sx={{ mt: 12, mb: 12 }}>
            <Typography variant="h3" component="h2" gutterBottom sx={{ mb: 6 }} >
                {title}
            </Typography>
            <Carousel
                navButtonsAlwaysVisible
                navButtonsProps={{
                    style: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        borderRadius: 0,
                    },
                }}
                navButtonsWrapperProps={{
                    style: {
                        bottom: '0',
                        top: 'unset',
                    },
                }}
                NextIcon={<ArrowForwardIos />}
                PrevIcon={<ArrowBackIos />}
            >
                {blogs.map((blog) => (
                    <Card key={blog.id} sx={{ maxWidth: 'sm', mx: 'auto', cursor: 'pointer', }}
                        onClick={() => handleBlogClick(blog)}
                    >
                        <CardMedia component="img" height="140" image={`${process.env.PUBLIC_URL}/${blog.image}`} alt={blog.title} />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {blog.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {blog.description}
                            </Typography>
                        </CardContent>
                    </Card>
                ))}
            </Carousel>
        </Box>
    );
};

export default BlogSnippets;
