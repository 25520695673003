import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const InvestorsPage = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Investors
      </Typography>
      <Typography variant="body1" paragraph>
        At our company, we are committed to delivering exceptional value to our
        investors. We strive for transparency, ethical business practices, and
        sustainable growth.
      </Typography>
      <Grid container spacing={4} sx={{ mt: 4 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>
            Our Mission
          </Typography>
          <Typography variant="body1">
            Provide a brief overview of your company's mission and values.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>
            Financial Information
          </Typography>
          <Typography variant="body1">
            Share relevant financial information, such as annual reports or
            investor presentations.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default InvestorsPage;
