import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { Card, CardContent, Divider, List, ListItem } from '@mui/material';
import CTA from '../../components/CTA';
import Footer from '../../components/Footer';
import getLPTheme from '../../getLPTheme';
import pimBanner from '../../assets/img/pim-banner.png';
import { Helmet } from 'react-helmet';
import CommonAppBar from '../../components/CommonAppBar';
import { logPageView } from '../../firebaseApp';

const StyledContainer = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(8, 0),
}));

const BannerContainer = styled('div')(({ theme }) => ({
    backgroundImage: `url(${pimBanner})`,
    borderRadius: theme.shape.borderRadius,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '400px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
    ...theme.applyStyles('dark', {
        boxShadow: '0 0 24px 12px hsla(267, 100%, 35%, 0.2)',
        outlineColor: 'hsla(210, 100%, 80%, 0.1)',
    }),
}));

const PIMArticle = () => {
    const LPtheme = createTheme(getLPTheme('dark'));

    React.useEffect(() => {
        logPageView('PIMArticle Page');
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);

    const articleContent = (
        <>
            <BannerContainer alt="Product Information Management" />

            <Typography variant="body2" paragraph>
                June 10, 2024
            </Typography>
            <Typography
                variant="h1"
                component="h1"
                gutterBottom
                sx={{ color: 'white' }}
            >
                Unlocking the Power of Product Information Management (PIM)
            </Typography>

            <Divider
                sx={{
                    backgroundColor: 'white',
                    width: '100%',
                    height: 1,
                    marginBottom: 2,
                }}
            />

            <Typography variant="body1" paragraph fontSize={18} my={5}>
                In today's competitive ecommerce landscape, effectively managing product information is crucial for businesses to succeed. Product Information Management (PIM) is a powerful solution that enables businesses to centralize, manage, and distribute product data across multiple channels. Let's explore the key benefits of implementing a PIM system.
            </Typography>

            <Card sx={{ mb: 4, px: 2 }}>
                <CardContent>
                    <Typography variant="h5" component="h2" gutterBottom mt={2}>
                        What is Product Information Management (PIM)?
                    </Typography>
                    <Typography variant="body1" paragraph mt={2}>
                        Product Information Management (PIM) is a system that allows businesses to collect, manage, and enrich product data from various sources and distribute it across multiple sales channels. It serves as a central repository for all product-related information, including descriptions, specifications, images, videos, and pricing.
                    </Typography>
                </CardContent>
            </Card>

            <Typography variant="h2" component="h2" gutterBottom mt={10}>
                Benefits of Product Information Management
            </Typography>

            <Divider />
            <List>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        <strong>Improved Data Quality and Consistency:</strong> PIM ensures that product information is accurate, complete, and consistent across all channels. It eliminates data silos and reduces the risk of errors, leading to improved data quality and reliability.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        <strong>Enhanced Customer Experience:</strong> With PIM, businesses can provide rich and detailed product information to customers. This includes high-quality images, videos, and comprehensive product descriptions, enabling customers to make informed purchasing decisions and reducing returns.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        <strong>Faster Time-to-Market:</strong> PIM streamlines the product onboarding process, allowing businesses to introduce new products to the market quickly. It enables efficient collaboration between teams, reducing the time and effort required to gather and distribute product information.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        <strong>Increased Sales and Conversion Rates:</strong> By providing accurate and compelling product information, businesses can improve customer trust and confidence. This leads to increased sales and higher conversion rates, as customers are more likely to make a purchase when they have access to comprehensive product details.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        <strong>Efficient Catalog Management:</strong> PIM simplifies catalog management by centralizing product data and automating the creation of product catalogs. It enables businesses to easily update and maintain product information across multiple channels, saving time and reducing manual effort.
                    </Typography>
                </ListItem>
            </List>

            <Typography variant="h2" component="h2" gutterBottom mt={10}>
                Case Studies
            </Typography>

            <Divider />
            <Card sx={{ mb: 4, px: 2, mt: 4 }}>
                <CardContent>
                    <Typography variant="h5" component="h3" gutterBottom mt={2}>
                        Case Study 1: IKEA
                    </Typography>
                    <Typography variant="body1" paragraph fontSize={18}>
                        IKEA, a global furniture retailer, implemented a PIM system to manage its extensive product catalog. With PIM, IKEA was able to centralize product information, improve data accuracy, and streamline the process of creating and distributing product catalogs across multiple countries and languages. The PIM system enabled IKEA to provide consistent and localized product information to customers, enhancing the overall shopping experience.
                    </Typography>
                </CardContent>
            </Card>

            <Card sx={{ mb: 4, px: 2, mt: 4 }}>
                <CardContent>
                    <Typography variant="h5" component="h3" gutterBottom mt={2}>
                        Case Study 2: Bosch
                    </Typography>
                    <Typography variant="body1" paragraph fontSize={18}>
                        Bosch, a leading manufacturer of home appliances and power tools, implemented a PIM system to manage product information across its global operations. With PIM, Bosch was able to centralize product data, ensure data consistency, and efficiently distribute product information to various sales channels. The PIM system enabled Bosch to improve product data quality, reduce time-to-market for new products, and enhance the customer experience by providing accurate and detailed product information.
                    </Typography>
                </CardContent>
            </Card>

            <Typography variant="h2" component="h2" gutterBottom mt={10}>
                Implementing a Product Information Management System
            </Typography>

            <Divider />
            <Typography variant="body1" paragraph fontSize={18} mt={2}>
                Implementing a PIM system requires careful planning and execution. Businesses should assess their current product information management processes, identify pain points, and define their requirements for a PIM solution. Partnering with a reputable PIM provider can help ensure a smooth implementation and seamless integration with existing systems.
            </Typography>

            <Typography variant="h2" component="h2" gutterBottom mt={10}>
                Conclusion
            </Typography>

            <Typography variant="body1" paragraph fontSize={18}>
                Product Information Management (PIM) is a critical tool for businesses looking to streamline their product data management, improve data quality, and enhance the customer experience. By centralizing product information, automating catalog management, and distributing accurate and compelling product data across multiple channels, PIM empowers businesses to drive sales, reduce returns, and stay competitive in the ecommerce landscape.
            </Typography>
            <Typography variant="body1" paragraph fontSize={18}>
                Harness the power of PIM and take your product information management to the next level. Streamline your operations, delight your customers, and unlock new opportunities for growth with a robust PIM solution.
            </Typography>
        </>
    );

    return (
        <ThemeProvider theme={LPtheme}>
            <Helmet>
                <title>Unlocking the Power of Product Information Management (PIM) | Sinteli Blog</title>
                <meta name="description" content="Discover how Product Information Management (PIM) can revolutionize your ecommerce operations. Centralize product data, improve data quality, enhance customer experience, and drive sales with a powerful PIM solution. Learn the benefits of implementing PIM and take your product information management to the next level." />
                <meta name="keywords" content="product information management, PIM, ecommerce, product data, catalog management, customer experience, data quality" />
            </Helmet>

            <CssBaseline />
            <CommonAppBar mode={'dark'} />
            <StyledContainer maxWidth="md" sx={{ mt: 10, px: 3 }}>
                {articleContent}
            </StyledContainer>
            <Box sx={{ bgcolor: 'background.default' }}>
                <CTA />
                <Divider />
                <Footer />
            </Box>
            <script type="application/ld+json">
                {`
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "headline": "Unlocking the Power of Product Information Management (PIM)",
      "image": "${pimBanner}",
      "author": {
        "@type": "Person",
        "name": "Sinteli Team"
      },
      "datePublished": "2024-08-10",
      "dateModified": "2024-08-10",
      "publisher": {
        "@type": "Organization",
        "name": "Sinteli",
        "logo": {
          "@type": "ImageObject",
          "url": "https://sinteli.com/logo.png"
        }
      },
      "description": "Discover how Product Information Management (PIM) can revolutionize your ecommerce operations. Centralize product data, improve data quality, enhance customer experience, and drive sales with a powerful PIM solution. Learn the benefits of implementing PIM and take your product information management to the next level."
    }
  `}
            </script>
        </ThemeProvider>
    );
};

export default PIMArticle;
