import * as React from 'react';
import { Typography, Container, Box, Divider, Grid, styled, CssBaseline, Stack, Card, CardMedia, CardContent } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import { logPageView } from '../../firebaseApp';
import getLPTheme from '../../getLPTheme';
import CommonAppBar from '../../components/CommonAppBar';
import Footer from '../../components/Footer';
import TextImageSection from '../services/components/TextImageSection';
import RequestServicesCard from '../services/components/RequestServicesCard';

const StyledContainer = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(8, 0),
}));

const features = [
    "Centralized order management",
    "Real-time inventory synchronization",
    "Automated order processing and fulfillment",
    "Multi-channel order consolidation",
    "Flexible shipping and delivery options",
    "Returns and exchange management",
    "Comprehensive order analytics and reporting",
];

const products = [
    'Product Information Management',
    'Order Management System',
    'Customer Data Platform',
];

const OMSPage = () => {
    const LPtheme = createTheme(getLPTheme('dark'));

    React.useEffect(() => {
        logPageView('OMS Page');
        window.scrollTo(0, 0);
    }, []);

    return (
        <ThemeProvider theme={LPtheme}>
            <Helmet>
                <title>Order Management System (OMS) - Sinteli</title>
                <meta name="description" content="Streamline your order management processes with our powerful OMS solution. Centralize orders from multiple channels, automate order processing and fulfillment, and gain real-time visibility into inventory levels and order status." />
                <meta name="keywords" content="order management system, OMS, order processing, fulfillment, inventory management" />
            </Helmet>

            <CommonAppBar mode={'dark'} />

            <CssBaseline />
            <Container sx={{ pb: 8 }}>
                <CommonAppBar mode={'dark'} />
                <StyledContainer sx={{ mb: 8, mx: 0, width: '100%' }}>
                    <Typography
                        variant="h2"
                        component="h1"
                        sx={{
                            mt: 8,
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: 'center',
                            fontSize: 'clamp(3rem, 10vw, 1.5rem)',
                            textAlign: 'center',
                        }}
                    >
                        Order Management System (OMS)
                    </Typography>
                    <CardMedia
                        component="img"
                        image="/order_main2.svg"
                        alt="Order Management System (OMS)"
                        sx={{
                            objectFit: 'contain',
                            width: '100%',
                            objectPosition: 'center',
                            height: '600px',
                            mb: 4,
                        }}
                    />


                    <Typography variant="body1" paragraph>
                        Streamline your order management processes with our powerful OMS solution. Centralize orders from multiple channels, automate order processing and fulfillment, and gain real-time visibility into inventory levels and order status. Our OMS empowers you to efficiently manage orders, improve customer satisfaction, and scale your business operations.
                    </Typography>

                    <Box sx={{ mt: 8, mb: 8 }}>
                        <Grid container spacing={2.5}>
                            {features.map((feature, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Stack
                                        direction="column"
                                        component={Card}
                                        spacing={1}
                                        useFlexGap
                                        sx={{
                                            color: 'inherit',
                                            px: 3,
                                            py: 1,
                                            height: '100%',
                                            border: '1px solid',
                                            borderColor: 'hsla(220, 25%, 25%, .3)',
                                            background: 'transparent',
                                            backgroundColor: 'grey.900',
                                            boxShadow: 'none',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            alignContent: 'center',
                                        }}
                                    >
                                        <div>
                                            <Typography gutterBottom sx={{ fontWeight: 'medium' }} variant='h6'>
                                                {feature}
                                            </Typography>
                                        </div>
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    <Divider sx={{ my: 4 }} />

                    <TextImageSection
                        title='Centralized Order Management'
                        description="Our OMS solution provides a centralized platform to manage orders from multiple sales channels, including e-commerce websites, marketplaces, and brick-and-mortar stores. Consolidate all your orders into a single system, enabling you to streamline order processing, reduce errors, and improve operational efficiency."
                        image="/oms-banner.png"
                        textOnLeft={false}
                    />

                    <TextImageSection
                        title='Automated Order Processing and Fulfillment'
                        description="Automate your order processing and fulfillment workflows with our intelligent OMS. From order validation and payment processing to inventory allocation and shipping, our system handles the entire order lifecycle. Reduce manual intervention, minimize errors, and accelerate order fulfillment to deliver a superior customer experience."
                        image="/article-2-banner.png"
                    />

                    <Grid container spacing={6} sx={{ py: 6, mb: 12 }}>
                        <Grid item xs={12} sm={6} md={6} key={0}>
                            <Card sx={{ height: '100%' }}>
                                <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        Real-Time Inventory Synchronization
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Keep your inventory levels accurate and up to date across all channels with real-time synchronization. Our OMS integrates with your inventory management system, ensuring that stock levels are automatically adjusted as orders are placed and fulfilled. Avoid overselling and stockouts, and maintain optimal inventory levels to meet customer demand.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} key={0}>
                            <Card sx={{ height: '100%' }}>
                                <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        Comprehensive Order Analytics and Reporting
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Gain valuable insights into your order management performance with our comprehensive analytics and reporting capabilities. Monitor key metrics such as order volume, fulfillment rates, shipping times, and customer satisfaction. Identify trends, optimize your processes, and make data-driven decisions to improve your order management efficiency and profitability.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <RequestServicesCard services={products} />

                </StyledContainer>

                <Divider />
                <Footer />
            </Container>
        </ThemeProvider>
    );
};

export default OMSPage;
