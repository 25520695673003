import * as React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledGrid = styled(Grid)(({ theme, textOnLeft }) => ({
    alignItems: 'center',
    flexDirection: textOnLeft ? 'row' : 'row-reverse',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
    },
}));

const StyledImageContainer = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(2),
    },
}));

const StyledImage = styled('img')(({ theme }) => ({
    width: '100%',
    height: 'auto',
    borderRadius: theme.shape.borderRadius,
}));

const TextImageSection = ({ title, description, image, altText, textOnLeft = true }) => {
    return (
        <StyledGrid container spacing={4} sx={{ mb: 4 }}>
            {textOnLeft ? (
                <>
                    <Grid item xs={12} md={6} order={{ xs: 2, md: textOnLeft ? 1 : 2 }}>
                        <Typography variant="h4" component="h2" gutterBottom>
                            {title}
                        </Typography>
                        <Typography variant="body1">{description}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} order={{ xs: 1, md: textOnLeft ? 2 : 1 }}>
                        <StyledImageContainer>
                            <StyledImage src={image} alt={altText} />
                        </StyledImageContainer>
                    </Grid></>
            ) : (
                <>
                    <Grid item xs={12} md={6} order={{ xs: 1, md: textOnLeft ? 2 : 1 }}>
                        <StyledImageContainer>
                            <StyledImage src={image} alt={altText} />
                        </StyledImageContainer>
                    </Grid>
                    <Grid item xs={12} md={6} order={{ xs: 2, md: textOnLeft ? 1 : 2 }}>
                        <Typography variant="h4" component="h2" gutterBottom>
                            {title}
                        </Typography>
                        <Typography variant="body1">{description}</Typography>
                    </Grid>
                </>
            )}
        </StyledGrid>
    );
};

export default TextImageSection;
