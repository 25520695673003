import * as React from 'react';
import { Box, Typography, TextField, Button, Grid, MenuItem, Card, Alert, CircularProgress } from '@mui/material';

const RequestServicesCard = ({ services, onSubmit }) => {
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [company, setCompany] = React.useState('');
    const [position, setPosition] = React.useState('');
    const [size, setSize] = React.useState('');
    const [revenue, setRevenue] = React.useState('');
    const [service, setService] = React.useState('');
    const [notes, setNotes] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [submissionError, setSubmissionError] = React.useState(null);
    const [submissionSuccess, setSubmissionSuccess] = React.useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmissionError(null);
        setSubmissionSuccess(false);

        if (!name || !email || !company || !position || !size || !revenue || !service) {
            setSubmissionError('Please fill in all required fields.');
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setSubmissionError('Please enter a valid email address.');
            return;
        }

        setIsLoading(true);

        try {
            const response = await fetch('https://api.airtable.com/v0/appLVBp9KdIAnakgS/tbl3yYZf2hPxV16NA', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`,
                },
                body: JSON.stringify({
                    records: [
                        {
                            fields: {
                                Name: name,
                                Email: email,
                                Phone: phone,
                                'Company Name': company,
                                Position: position,
                                'Company Size': size,
                                'Annual Revenue': revenue,
                                Service: service,
                                Notes: notes,
                            },
                        },
                    ],
                }),
            });

            if (response.ok) {
                setSubmissionSuccess(true);
                onSubmit({
                    name,
                    email,
                    phone,
                    company,
                    position,
                    size,
                    revenue,
                    service,
                    notes,
                });
                setName('');
                setEmail('');
                setPhone('');
                setCompany('');
                setPosition('');
                setSize('');
                setRevenue('');
                setService('');
                setNotes('');
            } else {
                setSubmissionError('Form submission failed. Please try again.');
            }
        } catch (error) {
            setSubmissionError('An error occurred while submitting the form. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Card sx={{
            pb: { xs: 4, sm: 6 },
            px: { xs: 4, sm: 6 },
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '8px',
            color: 'grey.100',
            background: 'linear-gradient(135deg, #3F1F5C 0%, #1D2D5B 100%)',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
        }} maxWidth='sm'>
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} sm={6}>
                    <Typography variant="h3" component="h2" gutterBottom>
                        Contact Us
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Reach out to us by filling out the following form, and our team will get back to you shortly.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    {submissionSuccess ? (
                        <Box sx={{ textAlign: 'center', py: 8 }}>
                            <Typography variant="h6" gutterBottom>
                                Thank you for your request!
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Our team will review your request and get back to you as soon as possible.
                            </Typography>
                        </Box>
                    ) : (
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" component="h3" gutterBottom>
                                        Personal Information
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Phone (Optional)"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant="h6" component="h3" gutterBottom>
                                        Company Information
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Company Name"
                                        value={company}
                                        onChange={(e) => setCompany(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Position"
                                        value={position}
                                        onChange={(e) => setPosition(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Company Size"
                                        value={size}
                                        onChange={(e) => setSize(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Annual Revenue"
                                        value={revenue}
                                        onChange={(e) => setRevenue(e.target.value)}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant="h6" component="h3" gutterBottom>
                                        Additional Information
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        select
                                        label="Topic"
                                        value={service}
                                        onChange={(e) => setService(e.target.value)}
                                    >
                                        {services.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                        <MenuItem key={'other'} value={'Other'}>
                                            Other
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={4}
                                        sx={{ minHeight: '120px' }}
                                        label="Additional Notes"
                                        value={notes}
                                        onChange={(e) => setNotes(e.target.value)}
                                    />
                                </Grid>
                                {submissionError && (
                                    <Grid item xs={12}>
                                        <Alert severity="error">{submissionError}</Alert>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        disabled={isLoading}
                                    >
                                        {isLoading ? <CircularProgress size={24} /> : 'Submit'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Card>
    );
};

export default RequestServicesCard;
