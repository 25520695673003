import * as React from 'react';
import { Typography, Container, Box, Divider, Grid, styled, CssBaseline, Stack, Card, CardMedia, CardContent } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import { logPageView } from '../../firebaseApp';
import getLPTheme from '../../getLPTheme';
import CommonAppBar from '../../components/CommonAppBar';
import Footer from '../../components/Footer';
import RequestServicesCard from './components/RequestServicesCard';
import BlogSnippets from './components/BlogSnippets';
import TextImageSection from './components/TextImageSection';

const StyledContainer = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(8, 0),
}));

const services = [
    "E-commerce website development",
    "Mobile app development",
    "Custom integrations with third-party systems",
    "API development and integration",
    "Custom feature development",
    "UI/UX design and optimization",
    "Performance optimization and scalability",
];

const CustomDevelopmentPage = () => {
    const LPtheme = createTheme(getLPTheme('dark'));

    React.useEffect(() => {
        logPageView('Custom Development Page');
        window.scrollTo(0, 0);
    }, []);

    return (
        <ThemeProvider theme={LPtheme}>
            <Helmet>
                <title>Custom Development - Sinteli</title>
                <meta name="description" content="Our skilled developers can build custom solutions tailored to your specific business requirements. Whether you need a new e-commerce website, a mobile app, or integrations with third-party systems, we've got you covered." />
                <meta name="keywords" content="custom development, e-commerce, website, mobile app, integrations" />
            </Helmet>

            <CommonAppBar mode={'dark'} />

            <CssBaseline />
            <Container sx={{ pb: 8 }}>
                <CommonAppBar mode={'dark'} />
                <StyledContainer sx={{ mb: 8, mx: 0, width: '100%' }}>
                    <CardMedia
                        component="img"
                        image="/custom_development.svg"
                        alt="Custom Development"
                        sx={{
                            objectFit: 'cover',
                            width: '100%',
                            objectPosition: '0 0',
                            height: '600px',
                            mb: 4,
                        }}
                    />

                    <Typography variant="h2" component="h1" gutterBottom>
                        Custom Development Services
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Our skilled developers can build custom solutions tailored to your specific business requirements. Whether you need a new e-commerce website, a mobile app, or integrations with third-party systems, we've got you covered. We work closely with you to understand your unique needs and deliver high-quality, scalable solutions that drive your business forward.
                    </Typography>

                    <Box sx={{ mt: 8, mb: 8 }}>
                        <Grid container spacing={2.5}>
                            {services.map((service, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Stack
                                        direction="column"
                                        component={Card}
                                        spacing={1}
                                        useFlexGap
                                        sx={{
                                            color: 'inherit',
                                            px: 3,
                                            py: 1,
                                            height: '100%',
                                            border: '1px solid',
                                            borderColor: 'hsla(220, 25%, 25%, .3)',
                                            background: 'transparent',
                                            backgroundColor: 'grey.900',
                                            boxShadow: 'none',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            alignContent: 'center',
                                        }}
                                    >
                                        <div>
                                            <Typography gutterBottom sx={{ fontWeight: 'medium' }} variant='h6'>
                                                {service}
                                            </Typography>
                                        </div>
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    <Divider sx={{ my: 4 }} />

                    <TextImageSection
                        title='Tailored E-Commerce Solutions'
                        description="Our team of experienced developers specializes in building custom e-commerce websites that align with your brand, target audience, and business goals. We leverage the latest technologies and best practices to create intuitive, user-friendly, and visually appealing online stores that drive conversions and enhance customer engagement."
                        image="/global_ecom.svg"
                        textOnLeft={false}
                    />

                    <TextImageSection
                        title='Mobile App Development'
                        description="In today's mobile-first world, having a strong mobile presence is crucial. Our developers can build custom mobile apps for iOS and Android platforms, enabling you to reach and engage your customers on the go. We focus on creating seamless, intuitive, and feature-rich mobile experiences that complement your e-commerce strategy."
                        image="/tailored_ecom.svg"
                    />

                    <Grid container spacing={6} sx={{ py: 6 }}>
                        <Grid item xs={12} sm={6} md={6} key={0}>
                            <Card sx={{ height: '100%' }}>
                                <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        Seamless Integrations
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        We understand that your e-commerce ecosystem consists of various tools and systems. Our developers can create custom integrations between your website, mobile app, and third-party systems such as ERP, CRM, payment gateways, and shipping providers. We ensure seamless data flow and synchronization, enabling you to streamline your operations and provide a unified customer experience.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} key={0}>
                            <Card sx={{ height: '100%' }}>
                                <CardContent>
                                    <Typography variant="h5" component="div" gutterBottom>
                                        Scalable and Performant Solutions
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        We build custom solutions with scalability and performance in mind. Our developers optimize code, implement caching mechanisms, and leverage content delivery networks (CDNs) to ensure fast loading times and smooth user experiences, even during peak traffic. We also employ best practices for security and data protection to safeguard your business and customer information.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <BlogSnippets title={'Related Blog Posts'} />
                    <RequestServicesCard services={services} />

                </StyledContainer>

                <Divider />
                <Footer />
            </Container>
        </ThemeProvider>
    );
};

export default CustomDevelopmentPage;
