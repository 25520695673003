import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Divider, styled } from '@mui/material';
import Footer from '../components/Footer';
import about from '../assets/img/about.png';
import CTA from '../components/CTA';
import CommonAppBar from '../components/CommonAppBar';
import { logPageView } from '../firebaseApp';
import CommerceServiceCTA from '../components/CommerceServiceCTA';

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  padding: theme.spacing(8, 0),
}));

const AboutPage = () => {
  React.useEffect(() => {
    logPageView('About Page');
  }, []);

  return (
    <Container sx={{ py: 8 }}>
      <CommonAppBar mode={'dark'} />
      <StyledContainer maxWidth="md" sx={{ mt: 8, mb: 8 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" component="h1" gutterBottom>
            Our Mission
          </Typography>
          <Typography variant="body1" lineHeight={2} paragraph>
            At Sinteli, our mission is to transform how E-commerce brands build lasting relationships with their customers. <b>Acquisition cost is rising and customer loyalty is harder to earn</b>, and for many, the key to long-term success lies in fostering authentic, <b>personalized connections.</b>
          </Typography>
          <Typography variant="body1" lineHeight={2} paragraph>
            We are passionate about creating a platform that empowers brands to engage with their customers on a deeper level—beyond transactions, towards meaningful interactions. We help brands turn every customer experience into an opportunity for loyalty, advocacy, and growth.
          </Typography>
          <Typography variant="body1" lineHeight={2} paragraph>
            Our goal is to redefine customer engagement, enabling E-commerce brands to thrive in an increasingly competitive digital landscape. We’re here to support brands in maximizing their Customer Lifetime Value, while delivering memorable, community-driven shopping experiences.
          </Typography>
          <Typography variant="body1" lineHeight={2} paragraph>
            With Sinteli, loyalty isn’t just a possibility—it’s a strategy for sustained success.

          </Typography>
        </Grid>


      </StyledContainer>

      <Box sx={{ bgcolor: 'background.default' }}>
        <CommerceServiceCTA />

        <Divider />
        <Footer />
      </Box>
    </Container>
  );
};

export default AboutPage;
