import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Alert, CircularProgress, List, ListItem, ListItemText } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { logAbandonedForm, logError, logFormSubmission, logPageView } from '../firebaseApp';

const SignUpPage = () => {
    const location = useLocation();

    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        name: '',
        email: location.state?.email || '',
        password: '',
        businessName: '',
        businessType: '',
        position: '',
        companySize: '',
        annualRevenue: '',
        currentPlatforms: '',
        currentChallenges: '',
        goals: '',
        timelineForImplementation: '',
        budgetRange: '',
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [submissionSuccess, setSubmissionSuccess] = useState(false);

    React.useEffect(() => {
        logPageView('SignUp Page');
    }, []);

    React.useEffect(() => {
        const handleBeforeUnload = () => {
            if (formData.email) {
                logAbandonedForm(formData);
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [formData]);

    const handleNext = () => {
        const newErrors = validateStep(activeStep);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setErrors({});
    };

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const newErrors = validateStep(activeStep);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            setIsLoading(true);
            setSubmissionSuccess(false);

            try {
                // Send the form data to Airtable
                const response = await fetch('https://api.airtable.com/v0/appCMRJmUh0QPFrmq/tbl3IYtzxqg4W0d5c', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`,
                    },
                    body: JSON.stringify({
                        records: [
                            {
                                fields: {
                                    Name: formData.name,
                                    Email: formData.email,
                                    'Business Name': formData.businessName,
                                    'Business Type': formData.businessType,
                                    Position: formData.position,
                                    'Company Size': formData.companySize,
                                    'Annual Revenue': formData.annualRevenue,
                                },
                            },
                        ],
                    }),
                });

                if (response.ok) {
                    // Form submission successful
                    setSubmissionSuccess(true);
                    logFormSubmission('Sign Up', formData);
                    setFormData({
                        name: '',
                        email: '',
                        password: '',
                        businessName: '',
                        businessType: '',
                        position: '',
                        companySize: '',
                        annualRevenue: '',
                        currentPlatforms: '',
                        currentChallenges: '',
                        goals: '',
                        timelineForImplementation: '',
                        budgetRange: '',
                    });
                } else {
                    // Form submission failed
                    logError('Sign Up Form', 'Form submission failed');
                }
            } catch (error) {
                logError('Sign Up Form', error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const validateStep = (step) => {
        const newErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        switch (step) {
            case 0:
                if (!formData.name) {
                    newErrors.name = 'Name is required';
                }
                if (!formData.email) {
                    newErrors.email = 'Email is required';
                } else if (!emailRegex.test(formData.email)) {
                    newErrors.email = 'Invalid email address';
                }
                break;
            case 1:
                if (!formData.businessName) {
                    newErrors.businessName = 'Business Name is required';
                }
                if (!formData.businessType) {
                    newErrors.businessType = 'Business Type is required';
                }
                if (!formData.position) {
                    newErrors.position = 'Position is required';
                }
                if (!formData.companySize) {
                    newErrors.companySize = 'Company Size is required';
                }
                break;
            default:
                break;
        }

        return newErrors;
    };

    const steps = ['Personal Information', 'Business Details', 'Review'];

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <>
                        <TextField
                            required
                            id="name"
                            name="name"
                            label="Name"
                            value={formData.name}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            required
                            id="email"
                            name="email"
                            label="Email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />
                        {/* <TextField
                            required
                            id="password"
                            name="password"
                            label="Password"
                            type="password"
                            value={formData.password}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        /> */}
                    </>
                );
            case 1:
                return (
                    <>
                        <TextField
                            required
                            id="businessName"
                            name="businessName"
                            label="Business Name"
                            value={formData.businessName}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            color='secondary'
                        />
                        <TextField
                            required
                            id="businessType"
                            name="businessType"
                            label="Business Type"
                            value={formData.businessType}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            placeholder='i.e., Apparel, Retail, etc.'
                        />
                        <TextField
                            required
                            id="position"
                            name="position"
                            label="Your Position"
                            value={formData.position}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            placeholder='i.e., CEO, CTO, etc.'
                        />
                        <TextField
                            required
                            id="companySize"
                            name="companySize"
                            label="Company Size"
                            value={formData.companySize}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            placeholder='i.e., 100-500'
                        />
                        <TextField
                            id="annualRevenue"
                            name="annualRevenue"
                            label="Annual Revenue (Optional)"
                            value={formData.annualRevenue}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            placeholder='i.e., $100,000'
                        />
                    </>
                );
            case 2:
                return (
                    <>
                        <List>
                            <ListItem>
                                <ListItemText primary="Name" secondary={formData.name} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Email" secondary={formData.email} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Business Name" secondary={formData.businessName || 'N/A'} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Business Type" secondary={formData.businessType || 'N/A'} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Position" secondary={formData.position || 'N/A'} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Company Size" secondary={formData.companySize || 'N/A'} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Annual Revenue" secondary={formData.annualRevenue || 'N/A'} />
                            </ListItem>
                        </List>

                        <Typography variant="body2" sx={{ mt: 2 }}>
                            After submitting this form, you will receive a follow-up email at the provided email address with more detailed information and how to get started.
                        </Typography>
                    </>
                );
            default:
                return 'Unknown step';
        }
    };

    return (
        <Container maxWidth="md" sx={{ py: 8 }}>
            {submissionSuccess ? (
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="h4" component="h1" gutterBottom mb={5}>
                        Thank you for signing up!
                    </Typography>
                    <Typography variant="h6" component="h1" gutterBottom mb={5}>
                        We will be in touch shortly.
                    </Typography>
                    <Button href="/" mt={5}>Go back to the homepage</Button>

                </Box>
            ) : (
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="h4" component="h1" gutterBottom mb={5}>
                        Sign Up for Sinteli
                    </Typography>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Box component="form" sx={{ mt: 4 }}>
                        {getStepContent(activeStep)}
                        {Object.keys(errors).length > 0 && (
                            <Alert severity="error" sx={{ mt: 2 }}>
                                {Object.values(errors).join(', ')}
                            </Alert>
                        )}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                            {activeStep !== 0 && (
                                <Button onClick={handleBack}>Back</Button>
                            )}
                            {activeStep === steps.length - 1 ? (
                                isLoading ? (
                                    <CircularProgress />
                                ) : (
                                    <Button onClick={handleSubmit} variant="contained" color="primary" >
                                        Sign Up
                                    </Button>
                                )
                            ) : (
                                <Button variant="contained" color="primary" onClick={handleNext}>
                                    Next
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Box>
            )}

        </Container>
    );
};

export default SignUpPage;
