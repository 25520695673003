import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Link, CircularProgress, Alert } from '@mui/material';
import { logError, logFormSubmission } from '../firebaseApp';

const CTA = () => {
    const [email, setEmail] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [submissionError, setSubmissionError] = React.useState(null);
    const [submissionSuccess, setSubmissionSuccess] = React.useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmissionError(null);
        setSubmissionSuccess(false);

        if (email.trim() === '') {
            setSubmissionError('Please enter your email address');
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setSubmissionError('Please enter a valid email address');
            return;
        }

        setIsLoading(true);

        try {
            const response = await fetch('https://api.airtable.com/v0/appboi3QhIuRynMzo/tblwlvovfKcT4sP4i', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`,
                },
                body: JSON.stringify({
                    records: [
                        {
                            fields: {
                                Email: email,
                            },
                        },
                    ],
                }),
            });

            if (response.ok) {
                setSubmissionSuccess(true);
                logFormSubmission('CTA', { email });
                setEmail('');
            } else {
                setSubmissionError('Submission failed. Please try again.');
                logError('CTA Form', 'Submission failed');
            }
        } catch (error) {
            setSubmissionError('An error occurred while submitting. Please try again.');
            logError('CTA Form', error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container
            sx={{
                py: { xs: 6, sm: 8 },
                px: { xs: 4, sm: 6 },
                mb: { xs: 6, sm: 8 },
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                background:
                    'radial-gradient(circle at 50% 0%, hsl(267, 98%, 35%), hsl(267, 100%, 16%))',
                boxShadow: `0 8px 12px hsla(267, 98%, 42%, 0.2)`,

                borderRadius: '8px',
                color: 'grey.100',
            }}
        >
            {submissionSuccess ? (
                <>
                    <Typography variant="h6" sx={{ color: 'inherit', mb: 2 }}>
                        Thank you for joining our early access program!
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'inherit', mb: 4 }}>
                        We appreciate you reaching out to us. Our team will review your message and get back to you as soon as possible.
                    </Typography>
                </>
            ) : (
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Typography
                            component="h2"
                            variant="h4"
                            textAlign={{ xs: 'center', md: 'left' }}
                            sx={{ color: 'inherit', mb: { xs: 2, sm: 4 } }}
                        >
                            Be a Pioneer
                        </Typography>
                        <Typography variant="body1"
                            textAlign={{ xs: 'center', md: 'left' }}
                            sx={{ color: 'inherit', mb: 4 }}>
                            Take advantage of our early access program and be among the first to experience the future of ecommerce management. Your insights and feedback will play a crucial role in refining our platform to perfection.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                alignItems: 'center',
                                justifyContent: 'center',
                                mb: 1,
                            }}
                        >
                            <TextField
                                variant="outlined"
                                placeholder="Enter your email"
                                sx={{
                                    mb: { xs: 2, md: 0 },
                                    mr: { md: 2 },
                                    input: { color: 'inherit' },
                                    width: { xs: '100%', md: 'auto' },
                                }}
                                InputProps={{
                                    style: {
                                        color: 'inherit',
                                    },
                                }}
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={handleSubmit}
                                disabled={isLoading}
                            >
                                {isLoading ? <CircularProgress size={24} /> : 'Get Started'}
                            </Button>
                        </Box>
                        {submissionError && (
                            <Alert severity="error" sx={{ mt: 2 }}>
                                {submissionError}
                            </Alert>
                        )}
                        <Typography variant="caption" sx={{ textAlign: 'center' }}>
                            By clicking &quot;Start now&quot; you agree to our&nbsp;
                            <Link href="/terms" color="primary">
                                Terms & Conditions
                            </Link>
                            .
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </Container>
    );
};

export default CTA;
