import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Alert, CircularProgress, Divider, styled } from '@mui/material';
import Footer from '../components/Footer';
import CommonAppBar from '../components/CommonAppBar';
import { logPageView } from '../firebaseApp';

const StyledContainer = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    padding: theme.spacing(8, 0),
}));

const ContactPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [submissionError, setSubmissionError] = useState(null);
    const [submissionSuccess, setSubmissionSuccess] = useState(false);

    React.useEffect(() => {
        logPageView('Contact Page');
    }, []);

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrors({});
        setSubmissionError(null);
        setSubmissionSuccess(false);

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const newErrors = {};

        if (formData.name.trim() === '') {
            newErrors.name = 'Name is required';
        }
        if (formData.email.trim() === '') {
            newErrors.email = 'Email is required';
        } else if (!emailRegex.test(formData.email)) {
            newErrors.email = 'Invalid email address';
        }
        if (formData.message.trim() === '') {
            newErrors.message = 'Message is required';
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setIsLoading(true);

        try {
            const response = await fetch('https://api.airtable.com/v0/appV8hEWTlcuj664u/tblUvFFAXkpu9RpK6', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`,
                },
                body: JSON.stringify({
                    records: [
                        {
                            fields: {
                                Name: formData.name,
                                Email: formData.email,
                                Message: formData.message,
                            },
                        },
                    ],
                }),
            });

            if (response.ok) {
                setSubmissionSuccess(true);
                setFormData({
                    name: '',
                    email: '',
                    message: '',
                });
            } else {
                setSubmissionError('Form submission failed. Please try again.');
            }
        } catch (error) {
            setSubmissionError('An error occurred while submitting the form. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container sx={{ py: 8 }}>
            <CommonAppBar />
            <StyledContainer maxWidth="md" sx={{ mt: 8, mb: 8 }}>
                {submissionSuccess ? (
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="h4" component="h1" gutterBottom color="text.primary">
                            Thank you for contacting us!
                        </Typography>
                        <Typography variant="body1" paragraph color="text.secondary">
                            We appreciate you reaching out to us. Our team will review your message and get back to you as soon as possible.
                        </Typography>
                        <Button variant="contained" component="a" href="/" sx={{ mt: 4 }}>
                            Return to Homepage
                        </Button>
                    </Box>
                ) : (
                    <Grid container spacing={6} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" component="h1" gutterBottom>
                                Contact Us
                            </Typography>
                            <Typography variant="body1" paragraph>
                                We'd love to hear from you! Whether you have a question, need assistance, or want to explore potential collaborations, our team is here to help. Fill out the form below, and we'll get back to you as soon as possible.
                            </Typography>
                            <Box component="form" noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="name"
                                            name="name"
                                            label="Name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            fullWidth
                                            error={!!errors.name}
                                            helperText={errors.name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            id="email"
                                            name="email"
                                            label="Email"
                                            type="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            fullWidth
                                            error={!!errors.email}
                                            helperText={errors.email}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            id="message"
                                            name="message"
                                            label="Message"
                                            multiline
                                            rows={4}
                                            value={formData.message}
                                            onChange={handleChange}
                                            fullWidth
                                            sx={{ minHeight: '120px' }}
                                            error={!!errors.message}
                                            helperText={errors.message}
                                        />
                                    </Grid>
                                    {submissionError && (
                                        <Grid item xs={12}>
                                            <Alert severity="error">{submissionError}</Alert>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            fullWidth
                                            disabled={isLoading}
                                            sx={{
                                                background: 'linear-gradient(90deg, #B22CB3 0%, #c1fe72 100%)',
                                                color: 'black',
                                                fontWeight: 'bold',
                                                borderRadius: 2,
                                                border: 'none',
                                                padding: '10px 20px',
                                                fontSize: '1rem',
                                                textTransform: 'none',
                                                '&:hover': {
                                                    background: 'linear-gradient(90deg, #B22CB3 20%, #c1fe72 120%)',
                                                },
                                            }}
                                        >
                                            {isLoading ? <CircularProgress size={24} /> : 'Submit'}
                                        </Button>

                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" gutterBottom>
                                Our Office
                            </Typography>
                            <Typography variant="body1" paragraph>
                                246 Stewart Green
                                <br />
                                Calgary, AB T8A2C6
                                <br />
                                Canada
                            </Typography>
                            <Typography variant="h5" gutterBottom>
                                Contact Information
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Email: support@sinteli.com
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </StyledContainer>

            <Box sx={{ bgcolor: 'background.default' }}>
                <Divider />
                <Footer />
            </Box>
        </Container>
    );
};

export default ContactPage;
