import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { Divider, List, ListItem } from '@mui/material';
import CTA from '../../components/CTA';
import Footer from '../../components/Footer';
import getLPTheme from '../../getLPTheme';
import securityBanner from '../../assets/img/article-5-banner.png';
import { Helmet } from 'react-helmet';
import CommonAppBar from '../../components/CommonAppBar';
import { logPageView } from '../../firebaseApp';

const StyledContainer = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(8, 0),
}));

const BannerContainer = styled('div')(({ theme }) => ({
    backgroundImage: `url(${securityBanner})`,
    borderRadius: theme.shape.borderRadius,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '400px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
    ...theme.applyStyles('dark', {
        boxShadow: '0 0 24px 12px hsla(267, 100%, 35%, 0.2)',
        outlineColor: 'hsla(210, 100%, 80%, 0.1)',
    }),
}));

const DataSecurityArticle = () => {
    const LPtheme = createTheme(getLPTheme('dark'));

    React.useEffect(() => {
        logPageView('DataSecurityArticle Page');
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);

    const articleContent = (
        <>
            <BannerContainer alt="Data Security in Ecommerce" />

            <Typography variant="body2" paragraph>
                March 10, 2025
            </Typography>
            <Typography
                variant="h1"
                component="h1"
                gutterBottom
                sx={{ color: 'white' }}
            >
                The Importance of Data Security in Ecommerce and How We Ensure It
            </Typography>

            <Divider
                sx={{
                    backgroundColor: 'white',
                    width: '100%',
                    height: 1,
                    marginBottom: 2,
                }}
            />

            <Typography variant="body1" paragraph fontSize={18} my={5}>
                In the digital age, data security has become a top priority for businesses and consumers alike. With the rise of ecommerce, the need to protect sensitive information, such as personal details and financial data, is more critical than ever. At Sinteli, we understand the importance of data security and have implemented robust measures to ensure the safety and privacy of our customers' information.
            </Typography>

            <Typography variant="h2" component="h2" gutterBottom mt={10}>
                The Importance of Data Security in Ecommerce
            </Typography>

            <Divider />
            <Typography variant="body1" paragraph fontSize={18} mt={2}>
                Ecommerce involves the exchange of sensitive information, including personal details, payment information, and shipping addresses. Any breach or unauthorized access to this data can lead to severe consequences, such as identity theft, financial fraud, and reputational damage. Ensuring the security of customer data is not only a legal obligation but also a moral responsibility for ecommerce businesses.
            </Typography>
            <Typography variant="body1" paragraph fontSize={18}>
                Data security is essential for building trust and confidence among customers. When customers feel that their information is secure, they are more likely to engage in online transactions and become loyal to a brand. On the other hand, a data breach can erode customer trust and lead to significant financial losses for businesses.
            </Typography>

            <Typography variant="h2" component="h2" gutterBottom mt={10}>
                How We Ensure Data Security
            </Typography>

            <Divider />
            <Typography variant="body1" paragraph fontSize={18} mt={2}>
                At Sinteli, we have implemented a comprehensive set of security measures to safeguard our customers' data. Here are some of the key features of our data security framework:
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        <strong>Encryption:</strong> We employ industry-standard encryption techniques to protect data both in transit and at rest. All sensitive information, such as payment details and personal data, is encrypted using secure protocols to prevent unauthorized access.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        <strong>Secure Authentication:</strong> Our platform utilizes multi-factor authentication to verify user identities and prevent unauthorized access. We also implement strict password policies and regularly monitor for suspicious login attempts.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        <strong>Data Isolation:</strong> We maintain a strict separation between customer data and other system components. Each customer's data is stored in isolated environments, ensuring that even in the unlikely event of a breach, the impact is limited and contained.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        <strong>Regular Security Audits:</strong> We conduct regular security audits and vulnerability assessments to identify and address any potential weaknesses in our system. Our team of security experts continuously monitors for emerging threats and implements necessary updates and patches.
                    </Typography>
                </ListItem>
                <ListItem>
                    <Typography variant="body1" paragraph fontSize={18}>
                        <strong>Compliance with Industry Standards:</strong> Our data security practices are aligned with industry standards and best practices, such as the Payment Card Industry Data Security Standard (PCI DSS) and the General Data Protection Regulation (GDPR). We stay up-to-date with the latest security guidelines to ensure ongoing compliance.
                    </Typography>
                </ListItem>
            </List>

            <Typography variant="h2" component="h2" gutterBottom mt={10}>
                Conclusion
            </Typography>

            <Typography variant="body1" paragraph fontSize={18}>
                Data security is a critical aspect of ecommerce that cannot be overlooked. At Sinteli, we prioritize the protection of our customers' data and have implemented robust security measures to ensure its confidentiality, integrity, and availability. By investing in state-of-the-art security technologies, adhering to industry standards, and continuously monitoring for threats, we strive to provide a secure and trustworthy platform for our customers.
            </Typography>
            <Typography variant="body1" paragraph fontSize={18}>
                When you choose our product, you can have peace of mind knowing that your data is in safe hands. We are committed to maintaining the highest standards of data security and privacy, so you can focus on growing your business without worrying about the safety of your information.
            </Typography>
            <Typography variant="body1" paragraph fontSize={18}>
                Trust is the foundation of any successful ecommerce business. By prioritizing data security, we aim to build long-lasting relationships with our customers based on trust, transparency, and reliability. Join us in our mission to revolutionize ecommerce while keeping your data secure every step of the way.
            </Typography>
        </>
    );

    return (
        <ThemeProvider theme={LPtheme}>
            <Helmet>
                <title>The Importance of Data Security in Ecommerce and How We Ensure It | Sinteli Blog</title>
                <meta name="description" content="Discover the critical role of data security in ecommerce and how Sinteli ensures the safety and privacy of customer information. Learn about our robust security measures, compliance with industry standards, and commitment to building trust with our customers. Protect your data and grow your business with confidence using our secure ecommerce platform." />
                <meta name="keywords" content="data security, ecommerce, customer data, encryption, authentication, compliance, trust, privacy" />
            </Helmet>

            <CssBaseline />
            <CommonAppBar mode={'dark'} />
            <StyledContainer maxWidth="md" sx={{ mt: 10, px: 3 }}>
                {articleContent}
            </StyledContainer>
            <Box sx={{ bgcolor: 'background.default' }}>
                <CTA />
                <Divider />
                <Footer />
            </Box>
            <script type="application/ld+json">
                {`
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "headline": "The Importance of Data Security in Ecommerce and How We Ensure It",
      "image": "${securityBanner}",
      "author": {
        "@type": "Person",
        "name": "Sinteli Team"
      },
      "datePublished": "2025-01-10",
      "dateModified": "2025-01-10",
      "publisher": {
        "@type": "Organization",
        "name": "Sinteli",
        "logo": {
          "@type": "ImageObject",
          "url": "https://sinteli.com/logo.png"
        }
      },
      "description": "Discover the critical role of data security in ecommerce and how Sinteli ensures the safety and privacy of customer information. Learn about our robust security measures, compliance with industry standards, and commitment to building trust with our customers. Protect your data and grow your business with confidence using our secure ecommerce platform."
    }
  `}
            </script>
        </ThemeProvider>
    );
};

export default DataSecurityArticle;
